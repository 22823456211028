.is-experience-editor {
    .alert {
        padding: .75rem 1.25rem;
        border-radius: .25rem;
        margin: 0;
        text-shadow: 0 0 black;

        &-warning {
            color: #856404;
            background: #FFF3CD;
            border: 1px #FFEEBA solid;
        }
        &-info {
            color: #0c5460;
            background: #D1ecf1;
            border: 1px #bee5eb solid;
        }
        &-danger {
            color: #721c24;
            background: #f8d7da;
            border: 1px #f5c6cb solid;
        }
        &-success {
            color: #155724;
            background: #d4edda;
            border: 1px #c3e6cb solid;
        }
    }

    .dictionary-tooltip {
        cursor:default;
        border: 1px dashed #00b5cd;
    }

}