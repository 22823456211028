/**
*  --------------------------------
*  shared/Fonts
*  --------------------------------
*/
    
    $fontPath: '/assets/fonts';
 
    @font-face {
        font-family: 'Basis';
        src: url($fontPath + '/basis/BasisGrotesquePro-Light-Web.woff2') format('woff2'),
             url($fontPath + '/basis/BasisGrotesquePro-Light-Web.woff') format('woff');
    }

    @font-face {
        font-family: 'Basis';
        font-style: italic;
        src: url($fontPath + '/basis/BasisGrotesquePro-LightItalic-Web.woff2') format('woff2'),
             url($fontPath + '/basis/BasisGrotesquePro-LightItalic-Web.woff') format('woff');
    }

    @font-face {
        font-family: 'Basis';
        font-weight: bold;
        src: url($fontPath + '/basis/basis-grotesque-bold.woff2') format('woff2'),
             url($fontPath + '/basis/basis-grotesque-bold.woff') format('woff');
    }
 
    // @font-face {
    //     font-family: 'Basis';
    //     font-weight: bold;
        
    //     // Before buyed fonts arrive, for dev only
    //     src: url($fontPath + '/basis/basis_bold.otf') format("opentype"); 

    //     // src: url($fontPath + '/FontName.eot?#iefix');
    //     // src: url($fontPath + '/FontName.eot?#iefix') format('eot'),
    //     //      url($fontPath + '/FontName.svg#icons') format('svg'),
    //     //      url($fontPath + '/FontName.woff') format('woff'),
    //     //      url($fontPath + '/FontName.ttf') format('truetype');
    // }
 
    // @font-face {
    //     font-family: 'Basis';
    //     font-weight: 300;
        
    //     // Before buyed fonts arrive, for dev only
    //     src: url($fontPath + '/basis/basis_light.otf') format("opentype"); 

    //     // src: url($fontPath + '/FontName.eot?#iefix');
    //     // src: url($fontPath + '/FontName.eot?#iefix') format('eot'),
    //     //      url($fontPath + '/FontName.svg#icons') format('svg'),
    //     //      url($fontPath + '/FontName.woff') format('woff'),
    //     //      url($fontPath + '/FontName.ttf') format('truetype');
    // }