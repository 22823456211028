/**
 *  -----------------------------------------------
 *  config/VARIABLES and MIXINS
 *
 *      1. Paths
 *      2. Spacing/dimensions
 *      3. Fonts/mixins
 *      4. OOCSS helpers
 *      5. CSS3 transitions
 *      6. Global mixins
 *  -----------------------------------------------
 */

 $theme: 'bcf';

 @import './breakpoints.scss';
 @import './colors.scss';
 @import './spacings.scss';
 @import './typography.scss';
 @import './transitions.scss';

/*
* 1. Paths
****************************************************************/
$imgPath: '/assets/img';



/*
* 3. Fonts/mixins
****************************************************************/
$fontArial: arial, sans-serif;
$fontHelvetica: helvetica, arial, sans-serif; 
$fontBasis: Basis, helvetica, arial, sans-serif;
$fontIcons: 'icons';

/* Mixins */
@mixin icon($character, $size, $lineHeight, $display: inline-block) {
    content: $character;
    display: $display;
    @include font(icon, $size, $lineHeight);
}

@mixin font($family, $size, $lineHeight, $letterSpacing: 0) {
    $font: sans-serif;
    $weight: 400;
    @if $family=='icon' {
        $font: $fontIcons;
    }
    @else if $family=='arial' {
        $font: $fontArial;
    }
    @else if $family=='name' {
        $font: $fontDomaineDispRegular;
        $weight: 300;
    }
    @else {
        @error "Invalid font family: "+$family;
    }
    font: $weight #{$size}/#{$lineHeight} $font;
    @if $letterSpacing !=0 {
        letter-spacing: $letterSpacing;
    }
}

/*
     * 7. OOCSS helpers
     ****************************************************************/

/* Clearfix */

.cf:before,
.cf:after {
    content: "";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.cf {
    overflow: visible !important;
}

/* Vertical centering classes */

.va,
.vac {
    display: inline-block;
    vertical-align: middle;
}

.va {
    height: 100%;
    width: 0;
    &.bottom {
        vertical-align: bottom;
    }
    // @media #{$mqTabletP} {
    //     display: none;
    // }
}

/* Visibility classes */

.v_hd,
.v_hd_ib,
.v_large,
.v_large_ib,
.v_tablet,
.v_tablet_ib,
.v_tabletp,
.v_tabletp_ib,
.v_mobile,
.v_mobile_ib,
.v_mobiles,
.v_mobiles_ib {
    display: none !important;
}

@media #{$mqDesktop} {
    .v_desktop {
        display: block !important;
    }
    .v_desktop_ib {
        display: inline-block !important;
    }
    .v_tablet,
    .v_tablet_ib,
    .v_tabletp,
    .v_tabletp_ib,
    .v_mobile,
    .v_mobile_ib,
    .v_mobiles,
    .v_mobiles_ib {
        display: none !important;
    }
}

// @media #{$mqLarge} {
//     .v_large {
//         display: block !important;
//     }
//     .v_large_ib {
//         display: inline-block !important;
//     }
//     .v_tablet,
//     .v_tablet_ib,
//     .v_tabletp,
//     .v_tabletp_ib,
//     .v_mobile,
//     .v_mobile_ib,
//     .v_mobiles,
//     .v_mobiles_ib {
//         display: none !important;
//     }
// }

// @media #{$mqHd} {
//     .v_hd {
//         display: block !important;
//     }
//     .v_hd_ib {
//         display: inline-block !important;
//     }
//     .v_tablet,
//     .v_tablet_ib,
//     .v_tabletp,
//     .v_tabletp_ib,
//     .v_mobile,
//     .v_mobile_ib,
//     .v_mobiles,
//     .v_mobiles_ib {
//         display: none !important;
//     }
// }

@media #{$mqTablet} {
    .v_tablet,
    .v_tablet_o {
        display: block !important;
    }
    .v_tablet_ib,
    .v_tablet_o_ib {
        display: inline-block !important;
    }
    .v_desktop,
    .v_desktop_ib,
    .v_large,
    .v_large_ib,
    .v_hd,
    .v_hd_ib {
        display: none !important;
    }
    br.forced {
        display: none !important;
    }
}

// @media #{$mqTabletP} {
//     .v_tabletp {
//         display: block !important;
//     }
//     .v_tabletp_ib {
//         display: inline-block !important;
//     }
// }

@media #{$mqMobile} {
    .v_mobile {
        display: block !important;
    }
    .v_mobile_ib {
        display: inline-block !important;
    }
}

// @media #{$mqMobileS} {
//     .v_mobiles {
//         display: block !important;
//     }
//     .v_mobiles_ib {
//         display: inline-block !important;
//     }
// }

@media #{$mqTablet} {
    .desktop,
    .desktop_ib {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }
    .mobile_ib {
        display: inline-block !important;
    }
} // Inset custom visibility class below


/*
     * 9. Global mixins
     ****************************************************************/
