/*
 *
 *    Breadcrumb
 *
 */

.breadcrumb {
    position: relative;
    
    @media #{$mqTablet} {
        display: none;
    }

    @media #{$mqDesktop} {
        display: none;
        margin-left: -$largePadding;
        top: 0;
        width: calc(100% + #{$largePadding});
        height: $navHeight;
        // position: absolute;
        justify-content: space-between;

        .breadcrumb-is-opened & {
            display: flex;
        }

        .nav__ul {
            width: auto;
        } 
    
        .nav__ul li:not(:last-child) {
            font-weight: bold;
            position: relative;
            margin-right: $largePadding;
        }

        .nav__ul li:last-child {
            a {
                font-weight: normal;
            }
        }

        .nav__ul li:not(:first-child) a {
            &:before {
                content: '';
                display: inline-block;
                left: -38px;
                top: 58px;
                position: absolute;
                height: 1px;
                width: 15px;
                background: $secondaryColor;
                transition: background 0.3s ease-out;
            }
        }
    
        .nav__social {
            height: 100%;
            display: flex;
            align-items: center;
    
            button:not(:first-child) {
                margin-left: 20px;
            }
        }
    }

    .copy-sharer {
        position: relative;
        .copy-sharer__text {
            opacity: 0;
            position: absolute;
            background: $color;
            color: $backgroundColor;
            padding: 5px;
            top: -10px;
            left: 50%;
            transform: translate(-50%, 10%) scale(0.8);
            transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
        }

        &.is-copied {
            .copy-sharer__text {
                font-size: 0.8rem;
                opacity: 1;
                transform: translate(-50%, -100%) scale(1);
            } 
        }
    }
    
}
