@import 'components/list.scss';

@mixin rich-text-inner() {
    > h2:not([class]),
    > h3:not([class]),
    > h4:not([class]),
    > h5:not([class]),
    > h6:not([class]),
    > p,
    > img,
    > .list--bullet,
    > ol,
    > div[data-toggle-target] {
        @media screen {
            width: 66.666%;
            max-width: $contentMaxWidth;
        }
        align-self: center;

        @media print {
            max-width: none;
            width: 100%;
        }

        @media #{$mqMobile} {
            width: 100%;
        }
    }  

    > * + * {
        margin-top: $largePadding;
        @media #{$mqMobile} {
            margin-top:40px;
        }
        @media print {
            margin-top: 10px!important;
        }

    }
    > [data-toggle-target] {
        margin-top: 0;
        > * {
            margin-top: 40px;
        }
    }
}

.rich-text {
    display: flex;
    flex-direction: column;

    @media print {
        display: block;
    }

    .is-experience-editor & {
        > span {
            display: flex;
            flex-direction: column;
        }
    }

    @include rich-text-inner();

    .is-experience-editor & {
        > span {
            @include rich-text-inner();
        }
    }



    blockquote {
        width: 83.33%;
        max-width: $contentMaxWidth+200px;
        align-self: center;
        @media #{$mqMobile} {
            width: 100%;
        }
    }

    /** Heading **/
    h2 {
        &:not([class]) {
            @include medium-title();
        }
        // font-size: 2.8rem;
        &.heading--large {
            @media #{$mqDesktop} {
                margin-bottom: $largePadding;
            }
        }
        @media print {
            font-size:1.5rem!important;
        }
    }

    h2 + p {
        margin-top: 45px;
        @media print {
            margin-top: 15px;

        }        
    }

    h3 {
        &:not([class]) {
            line-height: 1.7;
            @include small-title();
        }
    }

    h3 + p, p + p {
        margin-top: 30px;
        @media print {
            margin-top: 5px;

        }
    }

    h4 {
        // font-weight: 700;
    }

    h4 + p {
        margin-top: 15px;
        @media print {
            margin:5px;
        }
    }

    p + .list {
        margin-top: 30px;
        @media print {
            margin-top:10px;
        }
    }

    .section__heading + .list {
        margin-top: 0;
    }

    h5 {
        margin-bottom: 15px;
        @media print {
            margin-bottom: 5px;
        }
    }

    /** Paragraph **/
    p {
        line-height: 1.77778;  
        margin-bottom: 0px;
    }

    > p {
        // width: 66.666%;

        @media #{$mqMobile} {
            width: 100%;
        }
    }

    

    /** Text **/
    blockquote {
        @include medium-title();
        p {
            quotes: "\201C" "\201D";
            
            html[lang="fr"] & {
                quotes: "« " " »";
            }
            line-height: 1.33;
    
            &:before {
                content: open-quote;
            }
            &:after {
                content: close-quote;
            }
        }

        footer {   
            @include small-title();
            display: flex;
            align-items: center;
            margin-top: 80px;
            &:before {
                content: '';
                width: 30px;
                display: inline-block;
                background: $black;
                margin-right: 30px;
                height: 2px;

            }
            @media print {
                margin-top: 5px;

            }
        }
    }
    
    a.cta  {
        margin-top: 90px;
    }
}
