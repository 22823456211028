.search-box {
    border-bottom: $navBorder;
    padding-bottom: $mediumPadding;
    margin-bottom: $largePadding*1.5;
    
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border .3s ease-out;
    cursor: text;

    @include icon--search('');

    &:after {
        position: relative;
        transition: transform .3s ease-out;
    }

    @include hover() {
        border-bottom-color: $color;

        &:after {
            transform: scale(1.1);
        }
    }
}