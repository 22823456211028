.callout {
    position: relative;
    margin: $sectionMarginBottom 0;

    @media #{$mqMobile} {
        margin: 80px 0;
    }

    @media print {
        margin:10px 0;
    }
    &:before {
        content: '';
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background: $blue;
        transition:  $calloutTransition;
        transition: $calloutTransition, width 0s $calloutTransitionDuration linear;
        width: 0;
        opacity:  0;

        @media print {
            content: none;
        }
    }
    .has-callout &:before {
        width: 100vw;
        opacity: 1;
        transition: $calloutTransition, width 0s 0s linear;
    }

        
    .carousel--full  > .carousel__wrapper > .carousel__slide {
        overflow: hidden;
    }
}
