/**
 *    -----------------------------------------------
 *    module/pagination
 *
 */

/*
 *
 * Global
 *
 */

.pagination {
    background: $backgroundColor;
    
    &.swiper-pagination-lock {
        display: none;
    }

    &--inverted {
        background: transparent;

        &.pagination--bullets .pagination__bullet {
            background-color: $white;
        }
    }
}

.pagination--bullets {
    display: flex;
    .pagination__bullet {
        @include bullet();
        margin: 6px;

        &:last-child {
            margin-right: 0;
        }
    }
}