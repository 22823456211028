/* Accessibility */

.nav_accessiblity {
    a {
        left: -4444px;
        position: absolute;
        top: -4444px;
    }
}

.tab_mode a:focus,
.tab_mode button:focus {
    outline: 1px dotted $black;
} // text hidding (practical for Document Outline)
.aria_hidden {
    border: none !important;
    clip: rect(0 0 0 0);
    font-size: 0 !important;
    height: 1px;
    line-height: 0 !important;
    margin: -1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px;
}