/*
 *
 *    Nav base
 *
 */
 
 


.nav_wrapper {
    position: relative;

    @media #{$mqTablet} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: $navHeight;
        height: calc(100vh - #{$navHeight});
        height: calc(var(--vh, 1vh) * 100 - #{$navHeight});
        width: 100%;
        opacity: 1;
        transform: translateX(-200%);
        transition: transform 0.3s ease-out;
        z-index: 201;

        .is-opened & {
            overflow-x: visible;
            transform: translateX(0);
        }

        .overlay & {
            top: 0;
            transform: translateX(0);
            height: 90px;
        }

        .sub-is-opened.is-opened & {
            transition: $translateTransition;
            transform: translateX(-100%);
        }
    }

    @media #{$mqMobile} {
        top: $navHeightMobile;
        min-height: calc(100vh - #{$navHeightMobile});
        min-height: calc((var(--vh, 1vh) * 100) - #{$navHeightMobile});

        .overlay & {
            top: 0;
            min-height: 0;
        }
    }
}

.nav {
    height: $navHeight;
    width: 100%;
    z-index: 80;
    padding: 0 $largePadding/2;
    
    @media #{$mqTablet} {
        padding: 0 $mediumPadding;
    }

    @media #{$mqMobile} {
        height: $navHeightMobile;
        padding: 0 $smallPadding;
    }
    
}
 
.nav__inner {
    height: 100%;
    width: 100%;

    @media #{$mqDesktop} {
        padding: 0 $largePadding/2;
    }
}
 
.nav__ul {
    display: flex;
    @media #{$mqDesktop} {
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        pointer-events: all;

        li {
            padding: 0 5px;
        }

        li:not(.nav__separator) {
            height: calc(100% + 1px);
        }
    }

}

.nav__link {
    @include flexCenter();
    height: 100%;
    transition: color 0.3s ease-out;

    @media #{$mqDesktop} {
        text-align: center;
    }
}

.nav__separator {
    content: '';
    height: 1px;
    width: 2%;
    background: $secondaryColor;
}