/* ==========================================================================
   toggle / module
   ========================================================================== */

// trigger content
.js {
    & [data-toggle-target] {
        display: none;
        // max-height: 0px;
        overflow: hidden;
        transition: max-height .6s $easeOutExpo;
    
        &.is-active {
            display: block;
        //     max-height: 100%;
        }
    }

    // button
    .btn-toggle {
        @include icon--more('before');
        // margin-bottom: $largePadding;
    
        &::before {
            margin-right: $xsPadding;
        }
    
        &[data-active="true"] {
            @include icon--less('before');
        }
    }
}



.no-js {
    & [data-toggle-target] {
        display: block;
    }
    & [data-toggle] {
        display: none;
    }
}
