/*
 *
 *    Nav Tools
 *
 */

.nav--tools {

    html.has-coveo-filters .header & {
        a.link--white {
            color: $black;

            &:after {
                background-color: $black; 
            }
        }
        .nav__inner {
            // border-color: #b7b8b9!important;
        }
    }

    .nav__link {
        font-weight: normal;
    }
    
    @media #{$mqDesktop} {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 60;
        width: 30%;
        transition: $gdprTransition;

        .gdpr-visible & {
            transform: translateY($gdprHeight);
        }

        /* Pseudo element for callout */
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            background: rgba(0,0,0,0.6);
            height: 100%;
            transform: scaleX(0);
            transform-origin: 100% 0;
            transition: transform 0.2s ease-out;
            z-index: -1;
        }

        .nav__inner {
            border-bottom: $navBorderInvert;

            .has-scrolling-right-panel & {
                border-bottom: $navBorder;
            }
            // border-color: $grayLight;
        }
    }

    @media #{$mqDesktopMedium} {
        .nav__ul  {
            justify-content: center;
        }
    }

    @media #{$mqTablet} {
        width: 100%;
       
        .nav__link {
            color: $color;
        }
        .nav__inner {
            border-top: $navBorder;
        }
        .nav__ul {
            
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            li, a {
                height: 100%;
            }
        }
        .link--bullet {
            width: 100%; 
            &:after {
                bottom: auto;
                background: $color;
                top: 0;
                transform: translate(-50%, -50%);
                // right: 0;
                // left: auto;
            }
        }
    }
}