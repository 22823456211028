.o-2col-title-text {
    position: relative;
}

.o-2col-title-text__title {
    margin: 0 auto $smallPadding;
    width: 66.666%;
    
    @media #{$mqMobile} {
        width: 100%;
    }
    
    @media #{$mqDesktop} {
        line-height: 1.77778;
        padding-right: $smallPadding;
        position: absolute;
        width: 14.286%; // 1/7
    }

    @media print {
        width: 100%;
        margin-bottom: 5px;
    }
}
