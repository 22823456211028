/**
 *  -----------------------------------------------
 *  shared/FOOTER
 *
 *  1. Generic
 *  -----------------------------------------------
 */

/*
     * 1. Generic
     ****************************************************************/

@mixin footer-base() {
    display: flex;
    flex-wrap: wrap;
    width: 100%;    
}

.footer {
    @include footer-base();
    z-index: 40;
    color: $white;  
    min-height: 300px;
    position: relative;
    background: $black;
    padding: $largePadding 0;

    .js & {
        opacity: 0;
    }
    
    

    @media print {
        display: none;
    }

    .is-experience-editor & {
        > .scWebEditInput {
            @include footer-base();
        }
    }

    @media #{$mqDesktop} {
        &:before {
            background-color: #1A1A1A;
            content: '';
            position: absolute;
            width: 1px;
            height: calc(100% - #{$largePadding});  
            top: $largePadding/2;
            right: 30%;
        }
    }

    @media #{$mqTablet} {
        flex-direction: column;
    }

    @media not print {
        &:after {
            background: $black;
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;  
            top: 0;
            left: 0;
            transform: translateY(100%);
        } 
    }

    .heading--normal {
        padding-bottom: 40px;
    }

    .left-pannel {
        overflow: hidden;

        @media print {
            width: 100%;
        }
    }

    .right-pannel {
        position: relative;

        @media print {
            display: none;
        }

            @media #{$mqTablet} {
                padding-top: $largePadding;
                margin-top:  $largePadding;
                &:before {
                    background-color: $grayDark;
                    content: '';
                    position: absolute;
                    width: calc(100% - #{$mediumPadding*2});
                    left: 0;
                    height: 1px;
                    left: $mediumPadding;
                    top: 0;
                }
            }

        @media #{$mqMobile} {
            &:before {
                width: calc(100% - #{$smallPadding*2});
                left: $smallPadding;
            }
        }
    }

    .carousel--text {
        padding-bottom: 0;

        @media print {
            .carousel__wrapper {
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .carousel__slide {
                margin-right: 0 !important;
                padding-right: $smallPadding !important;
                width: 30% !important;
            }
        }
    }

    .carousel--text .pagination {
        position: absolute;
        top: 10px;
        right: 0;
        transform: none;
    }

      
}

.address__phones a {
    display: flex;
    align-items: center;
    &:after {
        color: $grayDark;
        margin-left: 20px;
        
    }
}

.footer__social {
    display: flex;
    justify-content: space-around;
    
    @media #{$mqDesktop} {
        margin-top: $largePadding;
    }

    @media #{$mqMobile} {
        margin-top: $largePadding;
    }
}

.footer__legal {
    margin-top: $largePadding;
    
    .wrap {
        display: flex;
        @media #{$mqMobile} {
            flex-direction: column-reverse;
        }
    }

    .legal-links-wrapper {
        display: flex;

        @media #{$mqMobile} {
            flex-direction: column;
        }

        .link {
            padding-left: 35px;
            position: relative;
            &:before {
                content: '';
                display: inline-block;
                left: -20px;
                position: relative;
                top: -4px;
                height: 1px;
                width: 15px;
                background: $grayDark;
            }

            @media #{$mqMobile} {
                padding-left: 0;
                margin-bottom: $smallPadding;
                &:before {
                    // left: -30px;
                    // position: absolute;
                    // top: 6px;
                    display: none;
                }
            }
        }

    }

    .link {
        color: $grayDark;
    }
    
    a.link {
        @include textHover(white);
    }

}

.address {
    display: flex;
    flex-direction: column;
    font-weight: 200;
    line-height: 1.5;

    @media #{$mqDesktop} {
        max-width: 33.333333%;
    }

    // @media #{$mqTablet} {
    //     width: calc(50% + #{$largePadding/2});
    // }

    // @media #{$mqMobile} {
    //     width: calc(100% + 15px);
    // }

    &:not(:first-child) {
        // margin-left: 90px;
    }
    &:last-child a {
        margin-top: auto;
    }
    .address__phones {
        margin-top: 20px;
    }
}
