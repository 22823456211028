/*
 *
 *    Nav States
 *
 */

/*
 *  When Callout is active (Blue theme)
 */

 html.has-callout {
    .nav--main {
        &:after {
            opacity: 1;
        }
        .nav__inner {
            border-color: rgba($white, 0.2);
        }
        .nav__link {
            color: $white;
            border-color: rgba($white, 0.2);

            &:after {
                background: $white;
            }
            
            &:before {
                background: rgba($white, 0.2);
            }
        }
        .nav__separator {
            background: rgba($white, 0.2);
        }
        .nav__sub {
            background: $blue;
            .heading, a {
                color: $white;
            }
        }
        .breadcrumb {
            .nav__ul li:not(:first-child) a {
                &:before {
                    background: rgba($white, 0.2);
                }
            }
            button.link--black-to-gray {
                color: $white;
                &:hover {
                    color: rgba($white, 0.4);   
                }
            }
        }
    }
    
    .nav--tools {
        @media #{$mqTablet} {
            .nav__inner {
                border-color: rgba($white, 0.2);
            }
            .nav__link {
                color: $white;
                &:after {
                    background: $white;
                }
                
                &:before {
                    background: rgba($white, 0.2);
                }
            }
        }
    }

    .nav--bar  { 
        &:after {
            opacity: 1;
        }   
        .nav__inner {
            border-color: rgba($white, 0.2);
        }
        
    }

    .nav__bar-tools {
        .button {
            background: rgba($white, 0.3);
            color: $white;
            &.icon_burger {
                &:after, &:before {
                    background: $white;
                }
            }
        }
    }

    &.no-touchevents {
        .nav__bar-tools {
            .button {
                &:hover,
                &:focus {
                    color: $blue;
                    background: $white;
                }
                &.icon_burger {
                    &:hover,    
                    &:focus {
                        &:after, &:before { 
                            background: $blue;
                        }
                    }
                }
            }
        }
    }
}



/*
*  When Video is active and tool bar is active
*/
@if $theme != "bcf-blue" { 
    // Conditional code
    html.has-video, html.has-tool-bar {
    
        .nav--tools {
            @media #{$mqDesktop} {
                .nav__inner {
                    // border-color: rgba(0,0,0,0.2);
                }
                
                .button {
                    background: $black;
                    color: $white;
                }
                
                .nav__link {
                    color: $black;
                    &:after {
                        background: $black;
                    }
                    
                    &:before {
                        background: rgba($black, 0.2);
                    }
                }
            }
        }
        
        .nav--contact{
            @media #{$mqDesktop} {
                .nav__inner:before {
                    background-color: rgba(0,0,0,0.2);
                }
                
                .button {
                    background: $black;
                    color: $white;
                }
                .nav__link {
                    color: $black;
                    
                    &:before {
                        background: rgba($black, 0.2);
                    }
                }
            }
        }
    }
}

/*
* When the right panel can be scrollable (overlapping with sticky elements)
*/

html.has-scrolling-right-panel {
    .header:not(.overlay__header) .nav--tools {
        @media #{$mqDesktop} {
            background-color: $gray;
        }
    }
 }

/*
 *  Tool bar is active
 */
 html.has-tool-bar {
    .nav--contact {
        opacity: 0;
        background: linear-gradient(transparent, $gray);
        // transition: background 0.3s ease-out;
        &:hover, &:focus {
            background: $gray;

        }
    }
}





