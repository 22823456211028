.video {
    width: calc(100vw - #{$largePadding*2});
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 5;

    @media #{$mqTablet} {
        width: 100%;
    }
   
    &:before {
        content: '';
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($backgroundColor,0.8);
        z-index: -1;
        transform: translateX(-100%);
        transition: $videoTransition, transform 0s $calloutTransitionDuration linear;
        opacity: 0;
    }

    .video__link {
        display: block;
        width: 80%;
        margin: 0 auto;
        transform: scale(0.9);
        transition:  transform 0.6s ease-out;
        max-width: 1200px;
        position: relative;

        @media #{$mqDesktop} {
            width: 80%;
        }

        @media #{$mqLarge} {
            width: 70%;
        }

        @media #{$mqMobile} {
            width: 100%;
        }
    }

    .video__wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s ease-out;

        
    }

    &.is-ready  {
        .video__wrapper {
            opacity: 1;
        }
        .video__poster {
            z-index: 0;
            opacity: 0;
        }
    }

    @keyframes play-button-loading {
        0% {
            opacity: 0.8;
            transform: scale(1.1);
        }
        50% {
            opacity: 1;
            transform: scale(1.3);
            
        }
        100% {
            opacity: 0.8;
            animation-timing-function: ease-out;
            transform: scale(1.1);
        }
    }
      
    .video__poster {
        @include keep-ratio('1920/1080');
        transition-delay: inherit;
        width: 100%;
        transition: opacity 0.3s ease-out;
        z-index: 1;
        
        @include icon--play('after');
        &:after {
            // content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            left: 0;
            background: rgba($backgroundColor, 0.8);
            width: 80px;
            height: 80px;
            border-radius: 50%;
            transition: transform 0.3s ease-out;

            @media #{$mqMobile} {
                width: 70px;
                height: 70px;
            }
        }

        &:hover {
            &:after {
                transform: scale(1.1);
            }
        }

    }

    &.is-loading  {
        .video__poster:after {
            animation: play-button-loading 1.4s infinite both;
        }
    }



    .has-video & {
        .video__link {
            transform: scale(1);
            // opacity: 1;
        }
        &:before {
            transform: translateX(0);
            transition: $videoTransition, transform 0s 0s linear;
            opacity: 1;
        }
    }

}