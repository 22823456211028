.distinction-title {
    .heading--medium {
        max-width: 70%;
        width: 70%;
        margin: 0 auto;
        text-align: left;
        display: block;
        margin-bottom: $mediumPadding;

        @media print {
            max-width: none;
            width: 100%;
        }
    }
    p {
        max-width: 70%;
        margin: 0 auto;
        text-align: left;
        margin-bottom: $mediumPadding;
        width: 70%;
        @media print {
            max-width: none;
            width: 100%;
        }
    }
    @media #{$mqMobile} {
        .heading--medium {
            max-width: none;
        }
    }
}
.distinction-content {
    &.g.g_full_gutter {
        margin-top: 0;
        >.g_item {
            margin-top: 0;
        }
    }
    .g_item_cta {
        display: flex;
        align-items: center;
    }
    .cta.cta--center {
        margin-top: 0;
    }

    
}
