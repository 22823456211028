/**
 *  -----------------------------------------------
 *  shared/UTILITIES
 * (mobile first approach)
 *  -----------------------------------------------
 */

 /* margins */
.u-mt-lg--desktop {
    @media (max-width: #{$bpDesktop}) {
        margin-top: $largePadding;
    }
}

.u-color-gray {
    color: $gray !important;
}
