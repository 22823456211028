.page-list {
    @media print {
        margin-left: $smallPadding;
        margin-right: $smallPadding;
    }

    li {
        border: $navBorder;
        border-width: 0 0 1px 0;
        line-height: 1.3;
        // margin-left: -30px;

        @media print {
            border-color: #aaa;
        }


        &:first-child {
            padding-top: 0;
        }

        a {
            display: block;
            transition: transform 0.4s ease-out;
            text-decoration: none;
            
            .no-touchevents &:hover {
                // text-decoration: underline;
                transform: translateX(30px);
            }
        }
    }

    .section__heading {
        margin-bottom: 0;
    }

    &.page-list--medium, 
    &.page-list--normal {
        max-width: $contentMaxWidth;
        // margin: 0 auto;
        margin-left: auto;
        margin-right: auto;
        width: 66.666%;
        @media #{$mqMobile} {
            width: 100%;
        }
        
        @media print {
            padding-left: $smallPadding;
            padding-right: $smallPadding;
            max-width: none !important;
            width: 100% !important;
        }
    }

    &.page-list--normal {
        li {
            padding: 40px 30px;
            margin-left: -30px;
            @include normal-title();

            @media #{$mqMobile} {
                padding: 40px 0;
                margin-left: 0;
            }
            
            @media print {
                margin-left: 0;
                padding: 5px;

            }
        }   
    }

    &.page-list--medium {
        li {
            @include medium-title();
            padding: $smallPadding 30px;
            margin-left: -30px;

            @media #{$mqMobile} {
                padding: $smallPadding 0;
                margin-left: 0;
            }
        } 
    }

    &.page-list--large {
        li {
            @include normal-title();
            margin-left: -30px;
            max-width: 700px;
            padding: 40px 30px;
            
            @media #{$mqMobile} {
                padding: $smallPadding 0;
                margin-left: 0;
            }
        }
    }
}

ul.page-list {
    li:first-child {
        border-top-width: 1px;
    }
}
