
.products {
    margin-top: $xlPadding;
    margin-bottom: $largePadding;

    > .g_item {
        padding-bottom: $largePadding;
        
        @media #{$mqTablet} {
            padding-bottom: $mediumPadding;
        }
    }
    @media print{
        margin-top: 10px;
        margin-bottom: 10px;
        > .g_item {
            padding-bottom: 10px;

        }
    }
}


.products--overlap {
    position: relative;
    max-width: 1000px;
    // margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    // display: flex;

    @media #{$mqTabletP} {
        max-width: none;
    }
    & + .products--overlap {
        margin-top: 200px;
        @media #{$mqTabletP} {
            margin-top: 0;
        }
    }

    @media #{$mqTabletP} {
        display: flex;
        flex-direction: column;
    }

    .product {
        width: 50%;
        max-width: 500px;
        position: relative;
        
        @media #{$mqTabletP} {
            width: 100%;
            max-width: none;
        }

        &:nth-child(odd) {
            transform: translateX(10%);
            @media #{$mqTabletP} {
                transform: translateX(0);
            }
        }
        &:nth-child(even) {
            position: absolute;
            top: 20%;
            left: 50%;

            transform: translateX(-10%);

            @media #{$mqTabletP} {
                transform: translateX(0);
                left: 0;
                top: 0;
                margin-top: $largePadding;
                position: relative;
            }
        }
    }
}       

.product {
    @include keep-ratio('420/450');
    background-color: #004b9d;
    color: $white;
    overflow: hidden;
    position: relative;
    z-index: 1;

    @media not print {
        .js &:not(.is-loaded) {
            svg{
                opacity: 0;
            }
    
        }
    }

    @media print {
        // useless ratio keeper for print
        &::before {
            content: none;
        }

        a {
            position: static !important;
        }
        svg { display: none; }
    }

    h2 {
        font-weight: normal;
    }
    
    a {
        @include small-title();
        color: inherit;
        padding: $largePadding;
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 0;
        left: 0;

        @media #{$mqMobile} {
            padding: $smallPadding;
        }
        @media print{
            padding: 10px;

        }
    }

    svg {
        opacity: 0.2;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 340px;
        transform: translate(30%, 30%);
        z-index: -1;
        fill: currentColor;
        @media #{$mqMobile} {
            transform: translate(40%, 40%);
        }
    }

    &:hover {
        z-index: 2;
    }

    .icon {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        letter-spacing: 1px;

        @media print {
            display: none;
        }
    }



    // .product {
    //     background-color: #004b9d;
    //     position: relative;
    //     margin: 0 auto;
    //     margin-top: $largePadding*2;
        
    //     h3, a {
    //         color: $white;
    //     }
        
        
    // }

    &.product--gray {
        background-color: #c5c6c7;

        svg {
            fill: $black
        }

        h2, a,span {
            color: $black;
        }
    }

    @media #{$mqMobile} {
        width: 100%;
    }
    
}
