.key--numbers {

    &:not(.has-3-slides) .carousel__wrapper {
        justify-content: center;
        margin-left: 30px;

        @media #{$mqMobile} {
            margin-left: -9%;
            justify-content: flex-start;
        }
    }

    &.has-3-slides .carousel__wrapper {
        margin-left: 0;

        @media #{$mqMobile} {
            justify-content: flex-start;
            margin-left: -9%;
        }
    }

    @media print {
        .carousel__wrapper {
            justify-content: space-between !important;
            flex-wrap: wrap !important;
            margin-left: 0 !important;
        }
    }

    .carousel__slide {
        justify-self: center;
        .carousel__slide-inner {
            border-top: 1px $secondaryColor solid;  
            padding: 0;
            padding-top: 60px;

            @media #{$mqMobile} {
                width: 80%;
            }
            
            @media print {
                border-top: none;
                padding-top: 10px;

            }
        }

        @media print {
            //swiper override
            margin-right: 0 !important;
            width: auto !important;
        }
    }
    .heading--small {
        display: flex;
        flex-direction: column;
        font-weight: normal;
        align-items: flex-start;
        span {
            margin-top: -10px; // fix for typo natural line height
            padding-bottom: 30px;

            @media #{$mqMobile} {
                margin-top: -6px;
                margin-right: 5px;
            }
        }
    }

    .pagination {
        transition: background-color 0s 0s ease-out;
        top: 0px;
        @media #{$mqMobile} {
            top: 1px;
            right: auto;
            left: 78%;
            transform: translate(-50%, -50%);
        }
    }

    .callout & {
        .carousel__slide-inner {
            border-color: rgba(255,255,255,0.08);
        }
        .has-callout & .pagination {
            background-color: $blue;
            transition: background-color 0s 0.6s ease-out;
        }
    }
}
