ul.list {
    column-count: 2;
    column-gap: $largePadding;

    @media print {
        columns: auto;
    }

    .g_item &, 
    &.list--one-column {
        max-width: 900px;
        column-count: 1;
    }

    @media #{$mqMobile} {
        column-count: 1;
    }

    li {
        display: inline-block;
        flex-direction: column;
        margin-bottom: 25px;
        width: 100%;

        @media print {
            display: block !important;
            margin-bottom: 15px;

        }
    }
}

ul.list--bullet { 
    line-height: 1.75;

    li {
        flex-direction: row;
        position: relative;
        margin-left: 30px;
        padding-right: 30px;
        @media #{$mqMobile} {
            width: 100%;
        }
        @media print {
            margin-bottom: 5px;
        }

        &:before {
            @include bullet(true);
            position: absolute;
            left: -30px;
            top:  10px;
            display: inline-block;
            margin-right: 15px;
        }
    }
}

ul.list--pipe {
    width: 100%;

    li {
        padding-left: 30px;
        line-height: 1.5;
        position: relative;
        &:before {
            border-left: 2px solid $gray;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            // width: 2px;
            // background: $gray;
        }

        &:last-child {
            @media #{$mqMobile} {
                margin-bottom: $largePadding;
            }
        }
    }
    @media print {
        overflow: auto;
        max-width: 100%!important;

        li {

            width: 50%;
            padding: 0px 10px;
            float: left;
            &:nth-child(2n+1) {
                clear: both;
            }
        }
    }
}

ul.list--quote {
    width: 100%;
    li {
        padding-bottom: 45px;
    }
    @media print {
        overflow: auto;
        li {
            width: 50%;
            padding: 10px;
            float: left;
            margin-bottom: 0;
            &:nth-child(2n+1) {
                clear: both;
            }
            
        }
    }
}

.list--legal {
    counter-reset: item;

    ol {
        counter-reset: item;
        margin-top: $smallPadding;
    }
    
    li {
        display: block;
        line-height: 1.7;

        + li {
            margin-top: $xsPadding;
        }

        > h2:nth-child(1),
        > h3:nth-child(1),
        > h4:nth-child(1) {
            &::before {
                content: counters(item, '.') '. ';
                counter-increment: item;
            }
        }

        // Adds margin to direct child of a li
        // p + *,
        // ol + *,
        // ul + *,
        // button + * {
        * + *:not(div):not(a):not(button),
        div > p,
        [data-toggle] {
            margin-top: $smallPadding;
        }

        h4 + *,
        h5 + *,
        h6 + * {
            margin-top: 0 !important;
        }

        > h4:nth-child(1) {
            color: $gray;
            font-weight: bolder;
        }

        // margins
        h2 {
            margin-top: $largePadding;
        }
    }


    ul, ol {
        padding-left: 5%;
    }
}
