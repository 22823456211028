@mixin coveo-plus-rectangle() {
    content: '';
    display: block;
    width: 12px;
    height: 2px;
    background: $black;
    position: absolute;
    top: 5px;
    right: 0;
}

@mixin coveo-border() {
    content: '';
    width: calc(100% + #{$largePadding});
    height: 1px;
    background: rgba($black, 0.1);
    position: absolute;
    left: -$largePadding/2;

    @media #{$mqTablet} {
        width: calc(100% + #{$mediumPadding});
        left: -$mediumPadding/2;
    }

    @media #{$mqMobile} {
        width: calc(100% + #{$smallPadding});
        left: -$smallPadding/2;
    }   
}

.coveo-facet-header-eraser {
    display: none !important;   
}

.coveo-facet-value-exclude {
    display: none !important;
}

.CoveoResultLink, a.CoveoResultLink, .CoveoResult a.CoveoResultLink {
    color: inherit !important;
}

.CoveoResultLink:hover, a.CoveoResultLink:hover, .CoveoResult a.CoveoResultLink:hover {
    color: inherit !important;
}


.coveo-main-section {
    width: 100%;

    .Coveocoveo-facet-columnFacet {
        margin: 0 !important;
        background: transparent !important;
        padding: $largePadding 0 50px 0;
        border: 0 !important;
        width: 100%;

        @media #{$mqTablet} {
            padding: $mediumPadding 0 30px 0;
        }
        // Top border
        &:before {
            @include coveo-border();
            top: 0;
        }
        // Bottom border
        &:last-child {
            &:after {
                @include coveo-border();
                bottom: 0;
            }
        }



        .coveo-facet-header {
            background: transparent !important;
            padding: 0 !important;
            overflow: visible !important;
            cursor: pointer;
            position: relative;
            width: 100%;
            // Plus rectangle
            &:after {
                @include coveo-plus-rectangle();
            }
            // Plus rectangle
            &:before {
                @include coveo-plus-rectangle();
                transform: rotate(-90deg);
                transition: transform 0.3s ease-out;
            }

            .coveo-facet-header-title {
                @include small-title();
                color: $black;
            }

            .coveo-facet-header-wait-animation {
                /* TODO: remove the visibility line, only for dev purpose */
                // visibility: visible !important;
                top: -3px !important;

                .coveo-facet-header-wait-animation-svg g {
                    fill: $grayDark;
                }
            }
        }

        &.is-open {
            .coveo-facet-header {
                &:before {
                    transform: rotate(-180deg);
                }
            }
        }

        .coveo-facet-value-checkbox {
            width: 12px !important;
            height: 12px !important;

            svg {
                display: none;
            }
        }

        .coveo-facet-values {
            height: 0;  
            // max-height: 0;
            overflow: hidden;
            

            // transition: max-height .9s ease-out;
            // &:hover {
            //     max-height: 1000px;
            // }
        }

       

        .coveo-facet-value {
            padding: 0;

            &:first-child {
                margin-top: $largePadding;
            }

            background: transparent;

            &:not(:last-child) {
                margin-bottom: 25px;
            }

            &:hover, &.coveo-selected {

                .coveo-facet-value-checkbox {
                    &:after, &:before {
                        opacity: 1;
                    }

                    &:after {
                        transform: rotate(45deg) scale(1);
                    }

                    &:before {
                        transform: rotate(-45deg) scale(1);
                    }
                }
            }

            .coveo-facet-value-checkbox {
                top: 4px !important;

                &:before, &:after {
                    @include coveo-plus-rectangle();
                    top: 4px;
                    left: -2px;
                    width: 15px;
                    opacity: 0;
                    transition: 0.3s ease-out;
                    transition-property: opacity, transform;
                }

                &:after {
                    transform: rotate(45deg) scale(0);
                }

                &:before {
                    transform: rotate(-45deg) scale(0);
                }
            }
        }

        .coveo-facet-value-label {
            padding: 0;
        }
    }
}

.coveo-results-header {
    padding: 0 !important;
    box-shadow: none !important;
}

.CoveoCustomLoadMoreButton {
    z-index: 1;
    margin-top: $largePadding !important;
    align-self: center;
}


.CoveoSearchInterface {
    .coveo-summary-section {
        // @include small-title();
        color: $black !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 108px;


        .title {
            display: block;
            margin-top: $smallPadding;
            @include fluid-type($bpMobileP, $bpDesktopNormal, 20px, 24px);
            font-size: 2rem;
        }

        @media #{$mqTablet} {
            padding-bottom: $mediumPadding;
            margin-bottom: 0;
            padding-top: 30px;
        }

        .coveo-highlight {
            // margin-top: -20px;
            // margin-bottom: 14px;
            padding-right: 10px;
            @include large-title();
        }
    }

    .tool-bar--search .coveo-summary-section {
        margin-bottom: 6px;
    }


    .left-pannel .coveo-summary-section {
        //padding-bottom: $sectionMarginBottom;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 45px;

        .title {
            margin-top: 15px;
        }

        @media #{$mqMobile} {
            margin-top: -60px;
        }
    }

    .right-pannel .coveo-summary-section {
        .heading {
            display: block;
            margin-top: -10px;
            padding-bottom: 7px;

            @media #{$mqLarge} {
                padding-bottom: 4px;
            }
        }
    }
}

.CoveoSearchInterface {
    font-size: inherit !important;
    font-family: inherit !important;

    .coveo-search-interface-mandatory-section  {
        display: none;
    }

    .coveo-facet-column {
        margin: 0 !important;
        width: 100% !important;
        padding: 0 !important;
        opacity: 0;

        .coveo-loaded & {
            opacity: 1;
        }

        .CoveoFacet {
            background: transparent !important;
            width: 100% !important;
            padding: $largePadding 0 50px 0;
            border: 0 !important;
            margin: 0 !important;
            position: relative;
            // Top border
            &:before {
                @include coveo-border();
                top: 0;
            }

            @media #{$mqTablet} {
                padding: $mediumPadding 0 30px 0;
            }

            &.is-open {

                .coveo-facet-header {
                    &:before {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        // // Bottom border
        // &:last-child {
        //     .CoveoFacet:after {
        //         @include coveo-border();
        //         bottom: 0;
        //     }
        // }
        .coveo-facet-header {
            background: transparent !important;
            padding: 0 !important;
            overflow: visible !important;
            cursor: pointer;
            position: relative;
            width: 100%;
            // Plus rectangle
            &:after {
                @include coveo-plus-rectangle();
            }
            // Plus rectangle
            &:before {
                @include coveo-plus-rectangle();
                transform: rotate(-90deg);
                transition: transform 0.3s ease-out;
            }

            .coveo-facet-header-title {
                @include small-title();
                color: $black;
            }

            .coveo-facet-header-wait-animation {
                /* TODO: remove the visibility line, only for dev purpose */
                // visibility: visible !important;
                top: -3px !important;

                .coveo-facet-header-wait-animation-svg g {
                    fill: $grayDark;
                }
            }
        }



        .coveo-facet-value-checkbox {
            width: 12px !important;
            height: 12px !important;

            svg {
                display: none;
            }
        }

        .coveo-facet-values {
            height: 0;
            // max-height: 0;
            overflow: hidden;
            // transition: max-height .9s ease-out;
            // &:hover {
            //     max-height: 1000px;
            // }
        }

        .CoveoFacet.is-open {
            .coveo-facet-footer {
                display: flex;
            }
        }

        .coveo-facet-footer {
            &:empty { // When footer is empty
                display: none;
            }   

            display: none;
            flex-direction: row-reverse;
            margin-top: 40px;
            background: transparent;
            .coveo-facet-less, .coveo-facet-more {
                background: white;
                width: 100%;
                padding: 10px 20px;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                height: auto;
                transition: background 0.2s ease-out;
               
                .no-touchevents &:hover {
                    background: $black;
                    svg {
                        color: $white;
                    }
                    &:after, &:before {
                        color: $white;
                    }
                }

                .coveo-facet-more-icon, .coveo-facet-less-icon {
                    top: 0;
                }
                svg {
                    transition: color 0.2s ease-out;
                    color: $black;
                }
            }

            .coveo-active + .coveo-active {
                margin-right: 20px;
            }

            .coveo-facet-more {
                min-width: 66.66%;
                @include icon--more('');
                &:before {
                    font-size: 14px;
                    font-weight: bold;
                    text-transform: uppercase;
                    :lang(fr) & {
                        content: 'Voir plus';
                    }
                    :lang(en) & {
                        content: 'Load more';
                    }
                    margin-right: 10px;
                }
                &:after {
                    font-size: 12px;
                    color: $black;
                }
                svg {
                    display: none;
                }
            }
        }

        .coveo-facet-value {
            padding: 0;
            font-weight: normal;

            &:first-child {
                margin-top: 30px;
            }

            background: transparent;

            &:not(:last-child) {
                margin-bottom: 25px;
            }

            &:hover, &.coveo-selected {
                .coveo-facet-value-checkbox {
                    &:after, &:before {
                        opacity: 1;
                    }

                    &:after {
                        transform: rotate(45deg) scale(1);
                    }

                    &:before {
                        transform: rotate(-45deg) scale(1);
                    }
                }
            }

            .coveo-facet-value-checkbox {
                top: 5px !important;

                &:before, &:after {
                    @include coveo-plus-rectangle();
                    top: 4px;
                    left: -2px;
                    width: 15px;
                    opacity: 0;
                    transition: 0.3s ease-out;
                    transition-property: opacity, transform;
                }

                &:after {
                    transform: rotate(45deg) scale(0);
                }

                &:before {
                    transform: rotate(-45deg) scale(0);
                }
            }
        }

        .coveo-global-facets .coveo-facet-value {
            &:first-child {
                margin-top: 0;
            }
        }

        .coveo-facet-value-caption {
            // font-size: 1rem;
            // margin-top: -6px;
        }

        .coveo-facet-value-label {
            padding: 0;
        }
    }

    .CoveoResultList {
        opacity: 0;
        margin: 0;

        .coveo-loaded & {
            opacity: 1;
        }

        .coveo-result-list-container {
            // margin-right: 0;
            .coveo-card-layout {
                width: 50%;
                flex-shrink: 1;
                flex-grow: 0;
                margin: 0;
                font-size: inherit;
                padding: 0 $smallPadding;
                border: 0;
                overflow: visible;
                margin-bottom: 90px;

                &:nth-child(odd) {
                    padding-left: 0;
                }

                &:nth-child(even) {
                    padding-right: 0;
                }

                .article {
                    width: 100%;

                    a:hover {
                        text-decoration: none;
                    }
                }
            }

            .coveo-card-layout-padding {
                display: none;
            }
        }

        a,
        a:hover {
            color: inherit;
        }
    }

    .section--three-col {
        .CoveoResultList .coveo-result-list-container {
            margin-left: -$smallPadding;
            margin-right: -$smallPadding;

            @media #{$mqTablet} {
                margin-left: -$xsPadding;
                margin-right: -$xsPadding;
            }

            .coveo-card-layout {
                width: 33.333333%;
                transform: translateY(0px); //Fix for redraw of mix-blend-mode filter
                padding: 0 $smallPadding;

                @media #{$mqDesktopMedium} {
                    width: 50%;
                }

                @media #{$mqTablet} {
                    padding: 0 $xsPadding;
                }
            }
        }
    }

    @media #{$mqTablet} {
        // padding: 0 $mediumPadding;
    }

    @media #{$mqMobile} {
        .CoveoResultList {
            .coveo-result-list-container {
                .coveo-card-layout {
                    width: 100%;

                    &:nth-child(odd), &:nth-child(even) {
                        padding: 0;
                    }
                }
            }
        }

        .section--three-col {
            .CoveoResultList .coveo-result-list-container {
                margin-left: 0;
                margin-right: 0;

                .coveo-card-layout {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}


.coveo-global-facets {
    position: relative;
    padding-top: $sectionMarginBottom;

    @media #{$mqTablet} {
        padding-top: $largePadding;
    }

    @media #{$mqMobile} {
        padding-top: $mediumPadding;
    }

    &:before {
        @include coveo-border();
        top: 0;
    }

    .coveo-facet-header:after, .coveo-facet-header:before, .coveo-facet-value-checkbox {
        display: none !important;
    }

    .coveo-facet-header-title-section {
        display: none;
    }

    .coveo-facet-value-label-wrapper {
        @include small-title();
        color: $black;
    }

    .CoveoFacet {
        padding-top: 0 !important;

        &:before {
            content: unset !important;
        }

        .coveo-facet-values {
            padding-top: 0;
            
            .coveo-facet-value {
                margin: 0 0 30px;
            }
        }
    }

    .coveo-selected {
        .coveo-facet-value-count {
            display: none;
        }

        &:after {
            @include bullet();
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            opacity: 1;
        }
    }
}

[data-value="All"] {
    cursor: pointer;

    .coveo-facet-value-label {
        pointer-events: none !important;
    }
}

.coveo-results-column {
    overflow: visible !important; // added for ie11

    .coveo-sort-container{
        margin-right:15px;
        margin-left:15px;

        @media #{$mqDesktop} {
            margin-right:0px;
            margin-left:0px;
        }
    }
}

.coveo-sort-container{
    display: flex;
    justify-content: flex-end;


    @media #{$mqDesktop} {
        margin-bottom: $mediumPadding;
    }

    @media #{$mqTablet} {
        margin-bottom: $xsPadding;
    }

    @media #{$mqMobile} {
        justify-content: flex-start;
    }
}

.section.wrap {
    & > .coveo-sort-container{
        
        @media #{$mqDesktop} {
            margin-right:-20px;
        }

        @media #{$mqMobile} {
             margin-right:0px;
         }
    }
}