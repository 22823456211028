/**
*  --------------------------------
*  project/BASE
*  --------------------------------
*/

@import 'config/index.scss';

$theme: 'bcf-blue';
$backgroundColor: $blue;
$secondaryColor: rgba(255,255,255,.3);   
$color: $white;
$accentColor: $blue;
$imageSlideGradient: linear-gradient(to bottom, rgba(#0058b8,0.8) 0%, rgba(#0058b8,0.6) 40%, rgba(#0058b8,0.6) 60%, rgba(#0058b8,0.8) 100%);
$navBorder: 1px solid rgba(255,255,255,0.1);
$navBorderInvert: 1px solid rgba(255,255,255,0.1);

@import 'main.scss';
