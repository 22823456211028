a {
    color: $color;
    outline: none;
    text-decoration: none;
    line-height: 1.25;
    .no-touchevents & {
        &:active,
        &:focus {
            background-color: transparent;
        } // fix IE10 white background
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
    html.has-callout &:not(.nav__link):not(.logo) {
        color: $white;
    }
}

a.link, button.link {
    &--white {
        color: $white;

        &:not(.link--bullet) {
            @include textHover($gray);
        }
    }
    &--dark-gray {
        color: $grayDark;
        @include textHover($white);
    }
    &--black-to-gray {
        color: $color;
        @include textHover($secondaryColor);
    }
}

.link--normal {
    @include small-title();
}

.link--underline {
    text-decoration: none;
}

.link--bullet {
    position: relative;
    z-index: 1;
    &:after {
        @include bullet();

        
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }

    &.link--white {
        &:after {
            background-color: $white;
        }
    }
    
    &:hover, &:focus {
        &:after {
            opacity: 0.2;
        }
    }

    &.is-active {
        &:after {
            opacity: 1;
        }
    }
}

.link--circle-hover {
    position: relative;
    &:after {
        content: '';
        width: 60px;
        height: 60px;
        border-radius: 100%;
        border: 1px solid $grayDark;
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.4s ease-out;
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
        &:after {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
