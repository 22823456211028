/**
 *  -----------------------------------------------
 *
 *  shared/ICONS
 *
 *  1. Generic
 *  -----------------------------------------------
 */

@font-face {
    font-family: 'icons';
    src: url("/assets/fonts/icons/icons.eot?");
    src: url("/assets/fonts/icons/icons.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/icons/icons.woff?") format('woff'),
         url("/assets/fonts/icons/icons.ttf?") format('truetype'),
         url("/assets/fonts/icons/icons.svg#icons?") format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin getIcon($content, $pseudo: '') {
    @if $pseudo == 'before' {
        &:before {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            content: $content;
            font-family: 'icons';
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            speak: none;
            text-transform: none;
            @content
        }

        &:after { content: none; }
    } @else {
        &:after {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            content: $content;
            font-family: 'icons';
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            line-height: 1;
            speak: none;
            text-transform: none;
            @content
        }
    }
}

@mixin icon--arrow-right($pseudo: '') {
    @include getIcon("\ea01", $pseudo) {
        @content;
    }
}

.icon {
    &_arrow-right {
        @include icon--arrow-right('');
        
        &.icon_before {
            @include icon--arrow-right('before')
        }
    }
}

@mixin icon--chevron-left($pseudo: '') {
    @include getIcon("\ea02", $pseudo) {
        @content;
    }
}

.icon {
    &_chevron-left {
        @include icon--chevron-left('');
        
        &.icon_before {
            @include icon--chevron-left('before')
        }
    }
}

@mixin icon--close($pseudo: '') {
    @include getIcon("\ea03", $pseudo) {
        @content;
    }
}

.icon {
    &_close {
        @include icon--close('');
        
        &.icon_before {
            @include icon--close('before')
        }
    }
}

@mixin icon--fax($pseudo: '') {
    @include getIcon("\ea04", $pseudo) {
        @content;
    }
}

.icon {
    &_fax {
        @include icon--fax('');
        
        &.icon_before {
            @include icon--fax('before')
        }
    }
}

@mixin icon--filter($pseudo: '') {
    @include getIcon("\ea05", $pseudo) {
        @content;
    }
}

.icon {
    &_filter {
        @include icon--filter('');
        
        &.icon_before {
            @include icon--filter('before')
        }
    }
}

@mixin icon--less($pseudo: '') {
    @include getIcon("\ea06", $pseudo) {
        @content;
    }
}

.icon {
    &_less {
        @include icon--less('');
        
        &.icon_before {
            @include icon--less('before')
        }
    }
}

@mixin icon--link($pseudo: '') {
    @include getIcon("\ea07", $pseudo) {
        @content;
    }
}

.icon {
    &_link {
        @include icon--link('');
        
        &.icon_before {
            @include icon--link('before')
        }
    }
}

@mixin icon--facebook($pseudo: '') {
    @include getIcon("\ea08", $pseudo) {
        @content;
    }
}

.icon {
    &_facebook {
        @include icon--facebook('');
        
        &.icon_before {
            @include icon--facebook('before')
        }
    }
}

@mixin icon--mail($pseudo: '') {
    @include getIcon("\ea09", $pseudo) {
        @content;
    }
}

.icon {
    &_mail {
        @include icon--mail('');
        
        &.icon_before {
            @include icon--mail('before')
        }
    }
}

@mixin icon--more($pseudo: '') {
    @include getIcon("\ea0a", $pseudo) {
        @content;
    }
}

.icon {
    &_more {
        @include icon--more('');
        
        &.icon_before {
            @include icon--more('before')
        }
    }
}

@mixin icon--nav($pseudo: '') {
    @include getIcon("\ea0b", $pseudo) {
        @content;
    }
}

.icon {
    &_nav {
        @include icon--nav('');
        
        &.icon_before {
            @include icon--nav('before')
        }
    }
}

@mixin icon--phone($pseudo: '') {
    @include getIcon("\ea0c", $pseudo) {
        @content;
    }
}

.icon {
    &_phone {
        @include icon--phone('');
        
        &.icon_before {
            @include icon--phone('before')
        }
    }
}

@mixin icon--search($pseudo: '') {
    @include getIcon("\ea0d", $pseudo) {
        @content;
    }
}

.icon {
    &_search {
        @include icon--search('');
        
        &.icon_before {
            @include icon--search('before')
        }
    }
}

@mixin icon--linkedin($pseudo: '') {
    @include getIcon("\ea0e", $pseudo) {
        @content;
    }
}

.icon {
    &_linkedin {
        @include icon--linkedin('');
        
        &.icon_before {
            @include icon--linkedin('before')
        }
    }
}

@mixin icon--twitter($pseudo: '') {
    @include getIcon("\ea0f", $pseudo) {
        @content;
    }
}

.icon {
    &_twitter {
        @include icon--twitter('');
        
        &.icon_before {
            @include icon--twitter('before')
        }
    }
}

@mixin icon--youtube($pseudo: '') {
    @include getIcon("\ea10", $pseudo) {
        @content;
    }
}

.icon {
    &_youtube {
        @include icon--youtube('');
        
        &.icon_before {
            @include icon--youtube('before')
        }
    }
}

@mixin icon--external($pseudo: '') {
    @include getIcon("\ea11", $pseudo) {
        @content;
    }
}

.icon {
    &_external {
        @include icon--external('');
        
        &.icon_before {
            @include icon--external('before')
        }
    }
}

@mixin icon--play($pseudo: '') {
    @include getIcon("\ea12", $pseudo) {
        @content;
    }
}

.icon {
    &_play {
        @include icon--play('');
        
        &.icon_before {
            @include icon--play('before')
        }
    }
}

@mixin icon--medium($pseudo: '') {
    @include getIcon("\ea13", $pseudo) {
        @content;
    }
}

.icon {
    &_medium {
        @include icon--medium('');
        
        &.icon_before {
            @include icon--medium('before')
        }
    }
}

@mixin icon--upload($pseudo: '') {
    @include getIcon("\ea14", $pseudo) {
        @content;
    }
}

.icon {
    &_upload {
        @include icon--upload('');
        
        &.icon_before {
            @include icon--upload('before')
        }
    }
}

@mixin icon--check($pseudo: '') {
    @include getIcon("\ea15", $pseudo) {
        @content;
    }
}

.icon {
    &_check {
        @include icon--check('');
        
        &.icon_before {
            @include icon--check('before')
        }
    }
}

@mixin icon--mobile($pseudo: '') {
    @include getIcon("\ea16", $pseudo) {
        @content;
    }
}

.icon {
    &_mobile {
        @include icon--mobile('');
        
        &.icon_before {
            @include icon--mobile('before')
        }
    }
}


