.scrollmagic-pin-spacer {
    align-items: flex-start;    
    }
    
    .search {
        // background: red;
        //overwrite coveo default CSS
        .coveo-search-section {
            max-width: none !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    
        .CoveoSearchbox {
            margin-right: 0 !important; // coveo override
            margin-bottom: $sectionMarginBottom;
            position: relative;
    
            @media #{$mqMobile} {
                margin-bottom: $sectionMarginBottom / 2;
            }
            @media print {
                margin-bottom: $sectionMarginBottom / 2;
            }
    
            .CoveoForSitecoreExpressions, .CoveoForSitecoreConfigureSearchHub {
                display: none;
            }
    
            .magic-box .magic-box-input > input {
                background: none !important;
                border: 0 !important;
                font-family: $fontBasis !important;
                // font-size: 6.5rem!important;
                // @include fluid-type($bpMobileP, $bpDesktopNormal, $font-size-title-lg, $font-size-title-xl);
                @include large-title();
    
                @media #{$mqTablet} {
                    width: 100% !important;
                }
            }
    
            .magic-box {
                border: 0;
                width: 100%;
                position: relative;
                // overflowing border-bottom
                &::after {
                    border-bottom: $navBorder;
                    content: '';
                    position: absolute;
                    width: 100%;
    
                    @media #{$mqDesktop} {
                        left: -$largePadding/2;
                        width: calc(100% + #{($largePadding)});
                    }
                }
    
    
    
                .magic-box-input {
                    background: none;
                    height: auto;
    
                    > input {
                        // margin-left: -5px;
                        color: inherit;
                        padding: 0;
                        height: auto;
                        position: static;
                        text-indent: 0;
    
                        @include placeholder() {
                            color: $black;
                        }
                        // @media #{$mqTablet} {
                        //     font-size: 4.5rem!important;
                        // }
                    }
                }
    
                .magic-box-underlay {
                    z-index: -1;
                }
                    
                .magic-box-clear {
                    display: none;
                }
    
                .magic-box-suggestions {
                    padding-top: $smallPadding;
                    border: 0;
                    // display: block;
                    // position: static;
                    width: 100%;
                    top: calc(100% + 1px);
    
                    .CoveoExcerpt {
                        display: none;
                    }
    
                    .CoveoResult {
                        border: 0;
                        padding: 0 0;
    
                        .coveo-title {
                            font-family: $fontBasis;
                            font-size: 1.7em;
    
                            a {
                                color: $color;
                                padding: 25px;
                                display: block;
                                margin: 0;
    
                                .coveo-highlight {
                                    font-weight: bold;
                                }
    
                                @include hover() {
                                    text-decoration: none;
                                    background: $gray;
                                }
                            }
                        }
                    }
                }
                //temp
                &:hover {
                    .magic-box-suggestions {
                        display: block;
                    }
                }
            }
    
            .CoveoSearchButton {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 60px;
                border: 0;
                overflow: visible;
                position: absolute;
                right: -15px;
                top: 50%;
                transform: translateY(-50%);
                float: none; // coveo override
                button {
                    // flex: 0 1 30%;
                    display: flex;
                    align-items: center;
    
                    @include icon--arrow-right('before');
                    white-space: nowrap;
    
                    &:before {
                        padding-right: 10px;
                        transition: padding .3s ease-out;
                    }
    
                    @include hover() {
                        &:before {
                            padding-right: 5px;
                        }
                    }
    
                    @media #{$mqTablet} {
                        align-self: flex-end;
                    }
                }
    
                &:hover {
                    .coveo-search-button-svg {
                        opacity: 0.6;
                    }
                }
    
                .coveo-search-button-svg {
                    color: $black !important;
    
                    path {
                        fill: $black !important;
                    }
                }
    
    
                .coveo-search-button-loading {
                    display: none;
                }
    
                @media #{$mqTablet} {
                    .coveo-search-button {
                        button {
                            &:before {
                                font-size: 1.3rem;
                            }
    
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    
        .coveo-results-column {
            padding: 0 !important; // coveo override
            overflow: unset !important;
            margin-left: -$largePadding / 2;
            margin-right: -$largePadding / 2;
    
            @media #{$mqTablet} {
                margin-left: -$mediumPadding / 2;
                margin-right: -$mediumPadding / 2;
            }
    
            @media #{$mqMobile} {
                margin-left: -$smallPadding / 2;
                margin-right: -$smallPadding / 2;
            }
    
            .coveo-tab-section, .coveo-result-layout-section, .coveo-sort-section {
                display: none;
            }
    
            .coveo-results-header {
                margin-top: $mediumPadding;
                padding-left: 15px !important;
            }
    
            .CoveoQuerySummary {
                font-size: 1rem;
                padding-bottom: 0;
                display: block;
                text-align: right;
    
                span {
                    vertical-align: unset;
                    font-weight: normal;
                }
    
                .coveo-highlight {
                    font-size: 1rem;    
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
    
            .CoveoResultList {
                margin-top: 0;
    
                .coveo-list-layout {
                    border: 0;
    
                    @include hover() {
                        text-decoration: none;
                    }
                }

                .CoveoResultLink {
                    display: block;
                    @include hover() {
                        text-decoration: none;
                        background: lighten($gray, 20%);
                    }
                }
    
                a {
                    color: inherit;
                    text-transform: uppercase;
                    font-family: $fontBasis;
                    font-size: 1rem;
    
                    &:hover {
                        text-decoration: none;
                    }
                }
    
                .coveo-title {
                    a {
                        color: inherit;
                        text-transform: uppercase;
                        font-family: $fontBasis;
                        font-size: 1rem;
                        font-weight: bold;
                    }
                }
    
                .CoveoExcerpt {
                    padding-top: $smallPadding/2;
                    font-family: $fontBasis;
                    font-size: 1rem;
    
                    .coveo-highlight {
                        font-weight: bold;
                    }
    
                    &:empty {
                        display: none;
                    }
                }
            }
        }
    }
