.shortlist {
    .cta {
        margin-top: $largePadding;
    }
    &.shortlist--bi {
        @media print {
            display: none;
        }
    }

    &.shortlist--search {
        .g_item:nth-child(odd):not(:first-child) {
            margin-top: -90px;

            @media #{$mqMobile} {
                margin-top: 60px;
            }
        }

        @media print {
            .g {
                display: block; // instead of flex, solve ie11 overflow clip issue
                overflow: hidden;
            }
            .g_item {
                float: left; // solve ie11 overflow clip issue
                margin-top: $smallPadding !important;
                transform: none !important; // prevent js modification
            }
        }
    }
}
