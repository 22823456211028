.team_list {
  @media print {
    .coveo-result-list-container {
      margin-left: 0px!important;
      margin-right: 0px!important;
    }
    .coveo-card-layout{
      width: 20%!important;
      padding: 0 10px!important;
      margin-bottom: 20px!important;
    }
  }
}

.team_detail {
  .profile_picture_print {
    display: none;
  }
  @media print {
    .contact:nth-child(3) {
      display: none;
    }
    .section:first-child{
      page-break-after: always!important;
    }
    .profile_picture_print {
      display: block;
      position: absolute;
      top: -100px;
      right: 0;
      width: 100px;
      & + .introduction .tag-title, & + .introduction .heading--large {
        width: 75%;
      }
    }
    .g_1_2 {
      width: 100%!important;
    }
  }
}