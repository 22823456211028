 /** CTA **/

 a.cta, button.cta {
    padding: 50px 10px 50px 72px;
    margin-right: auto;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    overflow: visible;

    @media print {
        display: none !important;
    }

    .js &:not(.is-loaded) {
        opacity: 0;
    }

    .is-experience-editor & {
        opacity: 1 !important;

        .cta__text {
            opacity: 1 !important;
        }
    }
   
   &.cta--more {  
       .cta__text {
           @include icon--more('before');
       }
   }

    &.cta--arrow {    
       .cta__text {
           @include icon--arrow-right('before');
       }
    }

    &.cta--center {
        margin: 0 auto;
        margin-top: $largePadding*2;
        @media #{$mqMobile} {
            margin-top: $largePadding;
        }
    }

    &.cta--alone {
        margin-top: $largePadding;
    }
    
    .cta__text {
        @include small-title();

        display: block;
        position: relative;
    //    margin-left: 0;
        left: 30px;
        transition: left 0.3s ease-out, opacity 0.3s ease-out;
        font-weight: bold;
        opacity: 0;
        

       &:before {
            position: absolute;
            left: -22px;
            top: 50%;
            transform: translateY(-50%);
            transition: left 0.3s ease-out;
            z-index: -1;
       }
   }

   .no-touchevents &:hover,
   .no-touchevents &:focus {
       .cta__text {
           left: 10px;
           &:before {
               left: -32px;
           }
       }
   }

   &[data-cta-type="circle"] {
       svg {
           circle {
                stroke: $secondaryColor;
                html.has-callout & {
                    stroke: rgba($white, 0.3);
                }
           }
       }
   }

   &[data-cta-type="dots"] {
       svg {
           
           circle {
             fill: $secondaryColor;
             html.has-callout & {
                 fill: rgba($white, 0.3);
             }
           }
       }
       
       
   }

   svg {
       width: 110px;
       height: 110px;
       position: absolute;
       left: 0;
       top: 50%;
       transform: translateY(-50%) scale(1.25);
       z-index: -1;
       overflow: visible;
       circle {
           transform-origin: 50% 50%;
        }
        
        g {
            transform-origin: 50% 50%;
            
       }


   }

   
}


[data-module*="cta"] {
    svg {
        g {
            transform: scale(0.4);  
            transform-origin: 50% 50%;
            transition: 0.5s $easeOutExpo;
            transition-property: opacity, transform;
            opacity: 0;
        }
        
        g:nth-child(1) {
            transition-delay: 0.05s;
        }
        g:nth-child(2) {
            transition-delay: 0.1s;
        }
        g:nth-child(3) {
            transition-delay: 0.15s;
        }
    }

    

    &.is-loaded {
        opacity: 1 !important;

        .cta__text {
            opacity: 1;
            left: 0;
        }

        svg {
            g {
                transform: scale(0.85);
                opacity: 1;
           }
        }

        &:hover {
            
            svg g {
                transform: scale(1);
            }
        }
    }

 }
