/*
* 2. Spacing/dimensions
****************************************************************/

/** Sizes **/
$navHeight: 120px;
$navHeightMobile: 90px;
$rightPannelWidth: 30%;
$leftPannelWidth: 100% - $rightPannelWidth;


/* Spacings */
$xlPadding: 100px;
$sectionMarginBottom: 120px;
$globalMarginBottom: 25px;
$tabletPadding: 25px;
$mobilePadding: 15px;
$largePadding: 60px;
$mediumPadding: 40px;
$smallPadding: 30px;    
$xsPadding: 20px;    

$contentMaxWidth: 1000px;
$gdprHeight: 80px;
$filterHeight: 60px;
