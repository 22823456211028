/* ==========================================================================
   tooltip / module
   ========================================================================== */

[data-module="tooltip"] {
    color: $blue;
    
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
}

.tooltip {
    $tipWidth: 10px;

    color: $white;
    position: fixed;
    z-index: 500;

    .touchevents & {
        animation: tooltip-fade-to-black .7s $easeOutExpo forwards;        
        height: 100vh;
        left: 0 !important;
        top: 0 !important;
        width: 100vw;

        .tooltip__content {
            animation: tooltip-from-right .7s $easeOutExpo forwards;
            align-items: center;
            background: $blue;
            display: flex;
            height: 100%;
            justify-content: center;
            overflow: auto;
            padding: 20px 30px;
            width: 100%;

            // close
            @include icon--close('before');
            &::before {
                position: fixed;
                top: $tipWidth;
                right: $tipWidth;
            }
        }

        // touch tip moving out
        &:not(.is-active) {
            animation: tooltip-fade-from-black .7s $easeOutExpo backwards;

            .tooltip__content {
                animation: tooltip-to-right .7s $easeOutExpo backwards;
            }
        }

        

        @keyframes tooltip-from-right {
            from {
                transform: translateX(100%);
            }
            to {
                transform: translateX(0);
            }
        }
        @keyframes tooltip-to-right {
            from {
                transform: translateX(0);
            }
            to {
                transform: translateX(100%);
            }
        }
        @keyframes tooltip-fade-to-black {
            from {
                background: rgba($black, 0);
            }
            to {
                background: rgba($black, .5);
            }
        }
        @keyframes tooltip-fade-from-black {
            from {
                background: rgba($black, .5);
            }
            to {
                background: rgba($black, 0);
            }
        }
    }

    .no-touchevents & {
        background: $blue;
        max-width: 450px;
        padding: 20px 30px;

        @media #{$mqTabletP} {
            max-width: 300px;
        }

        @media #{$mqMobile} {
            max-width: calc(100% - 60px);
        }

        &::after {
            content: '';
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }

        &[data-position*="top"] {
            animation: tooltip-from-top .7s $easeOutExpo forwards;

            &::after {
                @include shape-arrow-down($tipWidth, $blue);
                top: 100%;
            }
        }
        
        &[data-position*="bottom"] {
            animation: tooltip-from-bottom .7s $easeOutExpo forwards;

            &::after {
                @include shape-arrow-up($tipWidth, $blue);
                bottom: 100%;
            }

            // tip top left
            &[data-position*="left"]::after {
                transform: rotate(90deg) translateX(25%);
            }

            // tip top right
            &[data-position*="right"]::after {
                transform: rotate(90deg) translateX(25%) scaleY(-1);
            }
        }

        &[data-position*="left"]::after {
            left: $tipWidth;
            transform: rotate(-90deg) translateX(25%);
        }

        &[data-position*="right"]::after {
            left: auto;
            right: $tipWidth;
            transform: rotate(-90deg) translateX(25%) scaleY(-1);
        }


        @keyframes tooltip-from-top {
            from {
                opacity: 0;
                transform: translateY(-10px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes tooltip-from-bottom {
            from {
                opacity: 0;
                transform: translateY(10px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

    }
}

.tooltip__content {
    line-height: 1.7;
}
