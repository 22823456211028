.tool-bar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    opacity: 0;
    z-index: 2; 
    width: 30%;
    transition: top 0.4s ease-out, opacity 0.4s ease-out;

    &.is-not-sticky {
        @media #{$mqDesktop} {
            position: absolute;
            bottom: 0;
            top: auto !important;
            transform: translateY(0) !important;
        }
    }

    .gdpr-visible & {
        top: $gdprHeight;
        min-height: calc((var(--vh, 1vh) * 100) - #{$gdprHeight});
    }

    .js:not(.coveo-loaded) & {
        opacity: 0;
    }

    .coveo-loaded & {
        opacity: 1;
    }

    .nav-is-open & {
        display: none !important;
        z-index: 0 !important;
    }
 
    @media #{$mqDesktop} {
        min-height: 100vh;
        padding-top: $navHeight;
        background: $gray;
    }

    @media #{$mqTablet} {
        z-index: 300; //Just underneath contact overlay
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        transform: translate3d(0,0,0);
        pointer-events: none;

        &.is-open {
            overflow: hidden;

            .tool-bar__inner {
                height: calc(100% - #{$filterHeight});
                transform: translateY(0);
                overflow-y: scroll;
                pointer-events: all;
                -webkit-overflow-scrolling: touch;

                .gdpr-visible & {
                    height: calc(100% - (#{$filterHeight + $gdprHeight}));
                }
            }
        }
    }

    .ps__rail-y {
        position: absolute;
        right: 12px !important;
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    .ps__thumb-y {
        position: absolute;
        background: rgba($black, 0.3);
        width: 5px;
        border-radius: 10px;
        right: 0;
    }
}

.tool-bar__inner {
    width: 100%;
    overflow: hidden;
    padding: $navHeight $mediumPadding 120px $mediumPadding;

    @media #{$mqTablet} {
        background: $gray;
        padding: $mediumPadding $mediumPadding 0 $mediumPadding;
        transition: transform 0.3s ease-out;
        transform: translateY(100vh);
    }

    &.ps--scrolling-y {

        .ps__rail-y {
            opacity: 1;
        }
    }
}

.scrollmagic-pin-spacer {
    width: 30%!important;
    min-width:unset!important;
    box-sizing: border-box!important;
    overflow: hidden;
    
    .tool-bar {
        margin: 0!important;

        &:not(.pinned) {
            width: 100%!important;
        }
    }
}

/* Small screen filters toggle */
.tool-bar__handle {
    backface-visibility: hidden;
    background: $gray;
    border-top: 1px solid rgba($black, 0.1);
    bottom: 0;
    display: flex;
    left: 0;
    height: $largePadding;
    overflow: hidden;
    pointer-events: all;
    position: fixed;
    width: 100%;

    @media #{$mqDesktop} {
        display: none;
    }


}

.tool-bar__handle-btn {
    display: flex !important; // overrides coveo styles
    flex: 1 0 100%;
    justify-content: space-between;

    font-size: 12px;
    line-height: 1.4;
    padding: $smallPadding / 2 $mediumPadding / 2.5;
    position: relative;
    transition: flex-basis .5s $easeOutExpo;

    + .tool-bar__handle-btn {
        align-items: center;
        
        &::before {
            background-color: rgba($black, 0.1);
            content: '';
            height: 60%;
            left: 0;
            position: absolute;
            width: 1px;
        }
    }

    .tool-bar__handle-label {
        position: relative;

        span {
            display: block;
            transition: .5s $easeOutExpo;
            transition-property: opacity, transform;
        }
        .inactive {
            position: absolute;
            left: 0;
            top: 0;
        }

        // Close label
        .active {
            opacity: 0;
            transform: translateY(300%);
        }
    }

    &:after {
        font-size: 18px;
        transition: opacity .5s $easeOutExpo;
    }

        // state
        .is-open & {
            flex-basis: 50%;
             justify-content: center;

            &:after {
                opacity: 0;
            }

            .tool-bar__handle-label {
                .inactive {
                    opacity: 0;
                    transform: translateY(-300%);
                }
                .active {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
}