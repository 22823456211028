/* Custom fixes */

// ios reset
input,
textarea {
    border-radius: 0;
    -webkit-appearance: none;
}

// Prevents "x" to clear field in explorer 11+
::-ms-clear {
    display: none;
} 
