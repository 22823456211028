.contact {
    margin-bottom: $largePadding;
    @media print {
        margin-bottom: 15px;

    }
    .contact__title {
        color: $gray;
        font-weight: bold;
        margin-bottom: 15px;
    }

    a, h5, address {
        line-height: 1.8;
    }

    h5 {
        margin-bottom: 0;
    }

    a {
        display: inline-block;
        text-decoration: underline;
        min-width: 100%;
    }

    address,
    .person {
        margin-bottom: $largePadding/2;
    }

    .link--phone {
        text-decoration: none;
    }

    .link--phone, .link--email {
        &:after {
            opacity: 0.3;
            display: inline-block;
            margin-left: 10px;
        }
        @include textHover($gray);
    }

    .link--email {
        text-decoration: underline;
        @include textHover($gray);
    }

}
