.introduction {
    h1 {
        padding-bottom: $largePadding;
        max-width: 900px;


        &:only-child {
            padding-bottom: 0;
        }

        @media #{$mqLarge} {
            max-width: 1200px;
        }

        @media #{$mqMobile} {
            padding-bottom: 0;  
        }
        @media print {
            padding-bottom: 20px;
        }
    }

    &.introduction--bi {
        padding-bottom: 45px;
        @media #{$mqMobile} {
            padding-bottom: 30px;  
        }
    }

    .tag-title {
        @include small-title();
        margin-top: 0;
        width: 100%;
        align-self: flex-start;
        color: $gray;
        font-weight: bold;
        margin-bottom: -30px;
        @media print {
            margin-bottom: 0px;
            
        }
    }

    p.summary {
        font-size: 1.5rem;
        line-height: 1.58;
        margin-bottom: 15px;
        @media #{$mqLarge} {
            font-size: 2rem;
        }
        @media print {
            font-size: 1.2rem;
            margin-bottom: 5px;

        }
    }
    .cta {
        margin-top: 0;
        display: inline-block;

        
        &:not(:first-child) {
            @media #{$mqDesktop} {
                margin-left: 20px;
            }
        }
        @media #{$mqTablet} {
            margin-top: 40px;
        }
    }

    .introduction__actions {
        margin-top: 16px;
    }
}

.introduction__title {
    &.has-aside {
        align-items: flex-end;
        display: flex;

        @media #{$mqTabletP} {
            flex-wrap: wrap;
        }

        .search-box {
            flex-shrink: 0;
            margin-left: auto;
            margin-bottom: $largePadding;
            width: calc(33% - #{$smallPadding});
            
            @media #{$mqDesktopMedium} {
                width: calc(50% - #{$xsPadding});
            }

            @media #{$mqTabletP} {
                margin-bottom: 0;
                margin-top: $largePadding;
                width: 100%;
            }
            
        }

    }
}
