/**
 *  -----------------------------------------------
 *  shared/GLOBAL
 *
 *  1. Base
 *  2. Headings
 *  3. Text
 *  4. Links/buttons
 *  5. Custom fixes
 *  6. Accessibility
 *  7. Print base
 *  -----------------------------------------------
 */

/*
     * 1. Base
     ****************************************************************/

     * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
        ::selection { background: $grayDark; color: $white; }
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
    
    html,
    body {
        height: 100%;

        @media print {
            height: auto;
        }
    }
    
    :root {
        @media screen {
            font-size: 16px;
        }
        @media print {
            font-size: 11px;
        }
    }
    @page {
        size: A4 portrait;
    }
    
    body {
        background-color: $backgroundColor; 
        color: $color;
        min-height: 100%;
        font-family: $fontBasis;
        // font-size: 1.125rem;
        @media screen {
            font-size: 1rem;
        }
        @media print {
            font-size: 11px;
        }
        html.has-callout & {
            color: $white;
        }


        overflow-x: hidden; //ie
        @supports (overflow: auto) {
            overflow: auto;
        }

        @media print {
            overflow: visible;

        }
    
        // Add pseudo element to body to be able to sync breakpoint with JS
        &:after {
            content: "none";
            display: none;
            /* Prevent from displaying. */
        }
    
        @media #{$mqLarge} {
            font-size: 1.125rem;
        }
    
        @media #{$mqDesktop} {
            &:after {
                content: "desktop";
            }
        }
        
        @media #{$mqTablet} {
            &:after {
                content: "tablet";
            }
        }
        
        @media #{$mqMobile} {
            &:after {
                content: "mobile";
            }
        }
    
    
        /* When an overlay is active */
        .overflow-is-hidden &,
        .overflow-is-hidden & .wrapper {
            height: 100%;
            overflow: hidden;
        }

        @media print {
            .overflow-is-hidden &,
            .overflow-is-hidden & .wrapper {
                height: auto;
                overflow: visible;
            }
        }
    
         
    } 
    
    // Main content wrapper (excluding header/footer)
    .main {
        margin: 0 auto;
        min-height: 100vh;
        width: 100%;
        overflow-x: hidden;
        transition: top .4s ease-out;
        position: relative;
        top: 0;

        @media print {
            overflow: visible !important;
            width: 95%;
            margin: auto;

        }

        &.two-cols {
            // display:flex;
            //     overflow-x : unset;
            //     align-items: flex-start;
                
            overflow-x : unset;
    
            .CoveoSearchInterface {
                min-height: 100vh;
                overflow-x : unset;
                position: unset;
                min-width: 0;
                align-items: stretch;

                @media #{$mqDesktop} {
                    display:flex!important;
                }
    
                .coveo-search-interface-mandatory-section, .CoveoForSitecoreContext {
                    display: none;
                }
                .left-pannel {
                    display: block;

                    @media #{$mqTablet} {
                        overflow-x: hidden;
                    }
                }

                
            }
            
        }
        
        @media not print {
            .gdpr-visible & {
                top: $gdprHeight;
            }
        }
    } 
    
    /** Wrapping container **/
    .wrap {
        margin: 0 auto;
        padding: 0 $largePadding;
        position: relative;
        // max-width: $bpDesktop; // Can be max-width with min-width
        width: 100%;

        @media print {
            padding-left: 0;
            padding-right: 0;
        }
    
        @media #{$mqTablet} {
            width: auto;
            padding: 0 $mediumPadding;
        }
    
        @media #{$mqMobile} {
            padding: 0 $smallPadding;
        }
    
        &--no-overflow {
            overflow: hidden;
        }
    }
    
    .left-pannel {
        width: $leftPannelWidth;
        @media #{$mqTablet} {
            width: 100%;
        }
        @media print {
            width: 100%;
        }
        
    }
    
    .right-pannel {
        width: $rightPannelWidth;
        @media #{$mqTablet} {
            width: 100%;
        }
    }
    
    /*
     * 2. Headings
     ****************************************************************/
    h1,
    h2,
    h3,
    h4,
    h5 {
        outline: none;

        @media print {
            display: block !important;
            position: static !important;
        }
    }
    
    .heading {
        &--large {
            @include large-title();
        }
        &--medium {
            @include medium-title();
        }
        &--normal {
            @include normal-title();
        }
        &--small {
            @include small-title();
        }
    }
    
    .heading--module {
        margin-bottom: $xlPadding;
        width: 70%;
    
        @media #{$mqMobile} {
            margin-bottom: $largePadding;
        }
        @media print {
            margin-bottom: 20px;
            
        }
    }
    
    
    /* Text */
    p {
        line-height: 1.77778;
        margin-bottom: 20px;
    }
    
    p, .rich-text li {
        a {
            text-decoration: underline;
        }
    }
    
    strong, .bold {
        font-weight: bold;
    }
    
    em, .italic {
        font-style: italic;
    }
    
    sup, 
    sub {
        line-height: 1;
        height: 0;
        position: relative;
        font-size: 0.7em;   
        margin-left: 0.2em;
    }
    
    sup {
        vertical-align: top;
    }
    
    sub {
        vertical-align: bottom;
    }
    
    hr {
        border: none;
        border-bottom: 1px solid #000;
    }
    
    .fine {
        letter-spacing: -0.2em;
    }
    
    .align_center {
        text-align: center;
    }
    
    img {
        height: auto;
        max-width: 100%;
        @media print{
            page-break-inside: avoid;
        }
    }
    
    figcaption {
        font-size: 0.75rem;
        opacity: 0.6;
    }
    
    svg {
        overflow: visible;
    }
    
    
    
    
    
    .gdpr, .msg {
        background: $blue;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $gdprHeight;
        position: fixed; 
        width: 100%;
        z-index: 200;
        transform: translateY(-$gdprHeight);
        transition: $gdprTransition;

        @media print {
            display: none;
        }

        span {
            font-family: $fontBasis;
            font-size: 1.3rem;
            padding-right: 15px;
            a {
                color: white;
                text-decoration: underline;
    
                @include hover() {
                    text-decoration: none;
                }
            }
        }
        .gdpr-visible & {
            transform: translateY(0px);    
        }
        .icon_close {
            color: $white;
            transition: transform .4s ease-out;
            transform: rotate(0);
            height: 20px;
            width: 60px;
            @include hover() {
                transform: rotate(90deg);
            }
        }
    
        @media #{$mqMobile} {
            padding: 20px;
          span { font-size: 14px; line-height: 1.2; }
        }
        
    }
    .msg {
        bottom: 0;
        left: 0;
        transform: translateY(0);
    }


    /*
     * 7. Print base
     ****************************************************************/

@media print {
    body:not(.p-team_detail) .main .right-pannel,
    body:not(.p-team_detail) .header .image-slide,
    .nav,
    .search,
    .video,
    .nav_wrapper,
    .search-box,
    .nav--contact,
    .overlay {
        display: none !important;
    }

    * {
        background-color: transparent !important;
        color: #000 !important;
    }

    p {
        page-break-inside: avoid;
    }

    .is-ie11 {
        .coveo-card-layout-container {
            display: block !important;
        }

        .coveo-card-layout {
            display: inline-block !important;
            vertical-align: top;
            width: 31% !important;
        }

        .g_full_gutter {
            display: block !important;

            .g_item {
                float: left !important;
            }
        }
    }

    .p-team_detail {
        [data-image-slide-notifier]:before{
            --imgUrl: attr(data-image-slide-notifier);
            content:url(var(--imgUrl));
            display: block;
            width: 100px;
            height: 100px;

        }
        
        .image-slide {
            display: block;
            position:absolute;
            top: 0;
            right: 0;
            width: 200px;
            height: 275px;
            opacity: 1;
            .image-slide__background {
                height: 100%;
            }
        }
    }
}
