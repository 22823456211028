/**
 *    
 *    Carousel 
 *
 */

/** Global **/
.carousel {
    position: relative;

    .carousel__wrapper {
        display: flex;
        position: relative; 
        width: 100%;
        height: 100%;

        @media print {
            // resets carousel position
            height: auto !important;
            transform: none !important;
            padding-bottom: 20px!important;
            display: block;
            overflow: hidden;
            // carousel inside a carousel
            .carousel__wrapper {
                width: 100vw!important;
            }
        }
    }

    .carousel__slide {
        position: relative;
        flex-shrink: 0;

        .js:not(.is-experience-editor) & {
            opacity: 0;
            transition: opacity 0.3s ease-out;

            &[data-animation-observer*="intersecting"] {
                opacity: 1;
            }
        }

        @media print {
            opacity: 1 !important;
            position: relative!important;
            top: 0!important;
            left: 0!important;
            bottom: 0!important;
            right: 0!important;
            margin-right: 0px!important;
            margin-bottom: 0px!important;
            padding-right: 20px!important;
            float: left!important;

        }
    }

    @media print {
        overflow: auto;
        .pagination {
            display: none;
        }
        & + .g {
            display: block;
            .g_1_2 {
                width: 100%;
            }
        }
    }
}

/** Services carousel **/
$slide-inner-width: 80%;
.carousel--services {
    .carousel__slide {
        transition-delay: 0s !important;
        transition: opacity 0.2s ease-in-out;
    }

    .carousel__slide-inner {
        width: $slide-inner-width;
    }

    .carousel__wrapper {
        margin-left: -9%;

        @media print {
            display: block !important;
        }
    }

    .carousel__slide:not(.is-active) {
        opacity: 0.3 !important;
    }

    .pagination {
        position: absolute;
        top: 9px;
        transform: translate(-50%, -50%);
        left: 77%;
    }

    .section__heading:after {
        left: 30px; 
        @media #{$mqMobile} {
            left: 0;
        }
    }
    
    @media print {
        .carousel__wrapper {
            flex-wrap: wrap !important;
            margin-left: $smallPadding !important;
            margin-right: $smallPadding !important;
            transform: none !important;
        }

        .carousel__slide,
        .carousel__slide-inner {
            margin-right: 0 !important;
            width: 100% !important;
        }

        .carousel__slide {
            margin-top: $largePadding;
        }
        
        .carousel__slide:not(.is-active) {
            opacity: 1 !important;
        }
    }
}

// Carousel full
.carousel--full {
    z-index: 21;

    .pagination {
        position: absolute;
        background: transparent;
        top: 30px;
        right: $largePadding;

        @media #{$mqLarge} {
            top: 50px;
        }
        @media #{$mqTablet} {
            top: 2%;
        }
        @media #{$mqMobile} {
            top: 1%
        }
        .pagination__bullet {
            background-color: $white;
        }
    }
    .carousel__slide {
        h2 {
            @media not print {
                margin-bottom: $largePadding;
            }
        }
        .carousel {
            margin-top: 120px;

            @media print {
                margin-top: 0;
            }
        }
        // opacity: 0;
        .rich-text > *:not(.heading--large) { 
            // display: none;
            // opacity: 0!important;
            // transition-delay: 0s!important;
        }
        &.is-active {
            // opacity: 1;

            .rich-text > *:not(.heading--large) { 
                // display: block;
                // opacity: 1!important;
            }   
        }
    }


    > .carousel__wrapper {
        @media print {
            display: block !important;
            flex-wrap: wrap;

            > .carousel__slide {
                //width: 100% !important;
            }

            > .carousel__slide + .carousel__slide {
                margin-top: 30px;
            }
        }
    }
    .carousel__slide-inner {
        padding: 0 $largePadding;
        @media #{$mqTablet} {
            padding: 0 $mediumPadding;
        }
        @media #{$mqMobile} {
            padding: 0 $mediumPadding;
        }

        @media print {
            padding-left: 10px;
            padding-right: 10px;
        }
        
    }
}

/** Carousel text **/
.carousel--text-3 {
    // overflow: hidden;
    padding-bottom: 50px;

    .carousel__slide-inner {
        padding-right: $largePadding;
    }


    .pagination {
        position: absolute;
        padding: 0 15px;
        top: 8px;
        transform: translateY(-50%);
        right: -15px;
    }

    @media #{$mqMobile} {
        .carousel__wrapper {
            margin-left: -9%;
        }
        .carousel__slide:not(.is-active) {
            opacity: 0.3;
        }
    }
    

    @media print {
        padding-bottom: 0;
        &:not(.key--numbers) .carousel__wrapper {
            flex-direction: column;
            // flex-wrap: wrap;
            //padding-left: $smallPadding;
            //padding-right: $smallPadding;
        }

        .carousel__slide {
            margin-right: 0 !important;
            //width: 100% !important;
        }
        .carousel__slide-inner {
            padding-right: 0;
        }

        &:not(.key--numbers) .carousel__slide {
            padding-top: $smallPadding / 2;
        }
    }
}

.carousel--employee {

    @media print {
        .carousel__wrapper {
            display: block !important;
            justify-content: space-between;
            flex-wrap: wrap;
            overflow: hidden;
        }
        .carousel__slide {
            float: left;
            margin-top: $smallPadding !important;
            margin-right: 0px !important;
            padding-right: 3% !important;
            width: 20% !important;
        }
    }

    @media #{$mqMobile} {
        margin-bottom: $largePadding;
    }
    .pagination {
        margin-left: $mediumPadding;
        position: absolute;
        top: 2.2rem;
        right: 0;
        @media #{$mqMobile} {
            // margin: $smallPadding 0;
            margin: 0;
            top: auto;
            bottom: -60px;
            right: auto;
            left: 0;
        }
    }
}

.carousel--offices {
    // display: none;
    width: 100%;
    overflow: hidden;
    @media #{$mqTablet} {
        padding: $mediumPadding;
    }
    @media #{$mqMobile} {
        padding: $smallPadding;
    }

    .article {
        .article__inner {
            padding: 60px; 
            &:after {
                content: none
            }
            @media #{$mqTablet} {
                padding: 0;
            }
            @media #{$mqMobile} {
                // padding: $smallPadding;
            }
        }
        &:not(.article--employee) {
            .article__img-wrapper:before  {
                opacity: 0;
            }
            .article__img-wrapper:after {
                content: none
            }
        }
        .article__img-wrapper {
            @include keep-ratio('1/1');

           
        }
        .contact {
            margin-top: 50px;
            margin-bottom: 0;
            p, .heading--small, a {
                color: $white;
            } 
            
            p {
                margin-top: 20px;

            }

            a {

            }
        }
    }
    .pagination_wrapper {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .article__inner {
            position: relative;
            padding: 60px; 
            &:after {
                content: none
            }
            
        }
        .article__img-wrapper {
            background: none;
            margin-bottom: 0;
            @include keep-ratio('1/1');
            &:before  {
                background: transparent
            }
            &:after {
                content: none
            }
        }
    }
    @media #{$mqMobile} {
        margin-bottom: $largePadding;
    }
    .pagination {
        background: transparent;
        position: absolute;
        bottom: -5px;
        right: 60px;
        .pagination__bullet {
            background-color: $black;
            opacity: 1;
            &.is-active {
                background-color: $white;
            }
        }
        @media #{$mqTablet} {
            bottom: auto;
            top: 0;
            right: $mediumPadding;
        }
        @media #{$mqMobile} {
            top: auto;
            bottom: -35px;
            // margin: $smallPadding 0;
            // margin: 0;
            // top: auto;
            // bottom: -60px;
            // right: auto;
            // left: 0;
        }
    }
}


.carousel--images {
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    max-width: $contentMaxWidth+200px;
    

    @media #{$mqMobile} {
        width: 100%;
    }

    .pagination {
        position: absolute;
        bottom: 0;
        padding-top: 30px;
        right: 0;
    }

    .carousel__wrapper {
        align-items: strech;
        flex-wrap: nowrap;
        padding-bottom: $largePadding;
    }

    figcaption {
        position: absolute;
        bottom: -$largePadding;
        left: 0;
        width: 50%;
        padding-bottom: 4px;
        @media print {
            bottom:5px;
            width: 100%;
        }
    }
    @media print {
        .carousel__slide{ 
            padding-bottom: 30px !important;
            width: 50%!important;

        }
    }
    
    .img_wrapper {
        @include keep-ratio('10/6');
    }

    img {
        display: block;
    }
}
