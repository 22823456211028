/**
 *    -----------------------------------------------
 *    module/Header
 *
 */

.header {
    height: $navHeight;
    position: relative;

    @media #{$mqMobile} {
        $navHeight: 90px;
        height: $navHeight;
    }
    @media print{
        width: 95%;
        margin: auto;
    }
        

    &.splash-screen-is-visible {
        .nav--main__ul { 
            visibility: hidden;
        }
    }
    
    .image-slide {
        transition: opacity 0.6s ease-out;
    }

    .js &:not(.is-ready) {
        .nav--main__ul { visibility: hidden; }
        .logo { visibility: hidden;}    
    }
}

// Splash screen 
.header {
    .logo {
        svg {
            position: relative;
        }
    }
}
