/**
 *  -----------------------------------------------
 *  module/OVERLAY
 *
 *  1. base (+ search/contact/career, etc)
 *  2. --simple
 *  -----------------------------------------------
 */

/*
   base (+ search/contact/career, etc)
   ========================================================================== */
.overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    // background: $blue;
    overflow-y: hidden;
    z-index: 300;

    &:before {
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        z-index: -1;
        width: 100%;
        height: 100%;
        background: $blue;
        content: "";
        transition: transform .3s ease-out;
    }
    .overlay-is-open & {
        @media #{$mqDesktop} {
            overflow-y: scroll;
        }
        &:before {
            transform: translateX(0);
        }
    }

    .main {
        top: 0 !important; // override gdpr stuff
     
    }

    .header {
        @media #{$mqTablet} {
            height: $navHeightMobile;
        }
    }

    .nav_wrapper {
        z-index: 400;
    }

    .nav--main {
        background: $blue;
        transform: translate(0)!important;
        margin-top: 0 !important; //override gdpr stuff
        display: none;
        @media #{$mqTablet} {
            height: 100%;
        }
        &:before, &:after {
            content: none;
        }
        .nav__inner {
            padding-left: 30px;
            @media #{$mqTablet} {
                padding: 20px 0;
                display: flex;
                align-items: center;
            }
        }
        .nav__ul {
            @media #{$mqTablet} {
                margin-top: 0;
            }
        }
        &.show {
            display: block;
        }
    }
    
    .nav--main, .nav--tools {
        .nav__inner {
            border-color: rgba($borderInvert,0.15);
        }
        .nav__ul {
            li:not(.nav__separator) {
                flex-shrink: 0;
                display: flex;
                align-items: center;
                color: $white;
                text-transform: uppercase;
                @include small-title();
            }
        }
    }

    .nav--tools {
        transform: translate(0)!important;
        display: none;
        background: $blue;
        overflow: hidden;

        .nav__inner {
            &:before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: 1px;
                background: rgba($borderInvert,0.15);
                height: 100vh;
                z-index: 301;

                @media #{$mqTablet} {
                    content: none;
                }
            }
        }
        
        
        @media #{$mqTablet} {
            position: absolute;
            background: transparent;
            right: 0;
            height: $navHeightMobile;
            top: 0;

            .nav__inner {
                border-top: 0;
                border-bottom: 1px solid rgba($borderInvert,0.15);

               
            }
        }

        .nav__ul {
            justify-content: space-between;
            @media #{$mqTablet} {
                justify-content: flex-end;
            }
            li:first-child {
                @media #{$mqTablet} {
                    display: none;
                }
            }
        }

        &.show {
            display: block;
        }
    }

    .image-slide {
        // position: relative;
        background: none;
        opacity: 1;
        // align-items: center;
        // padding-top: 60px;
        // border-left: 1px solid rgba(0,0,0,0.1);
        // border-color: rgba($borderInvert,0.15);

        

        &:before, &:after {
            content: none;
        }
    }
    
    .heading--large, p {

        color: $white;
    }
    p {
        margin-top: 60px;
    }
    .cta {
        .cta__text {
            color: $white;
        }
    }
    .cta[data-cta-type="circle"] {
        svg {
            .st0 {
                stroke: rgba(255,255,255,.2);
            }
        }
    }
    a.cta[data-cta-type="dots"] svg circle {
        stroke: rgba(255,255,255,.02);
        fill: rgba(255,255,255,.2);
    }
    .icon_close {
        color: $white;
        
        &::after {
            transform: rotate(0);
            transition: transform .4s ease-out;
        }

        @include hover() {
            &::after {
                transform: rotate(90deg);
            }
        }
    }
    .main {
        overflow: hidden;
        min-height: 100%;



        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        @media #{$mqTablet} {
            display: block;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            height: calc(100% - 90px);
            min-height: auto;
            padding-bottom: 60px;
        }

        .right-pannel {
            position: relative;
            // position: sticky;
            height: auto;
            top: 0;

            @media #{$mqTablet} {
                position: static;
                display: block;
                padding-top: 75px;
            }
            
            .right-pannel-content {
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                // display: flex;
                display: none;

                

                &.show {
                    display:block;
                }

                &[data-show-in="job"] {
                    height: calc(100% - 120px);
                    position: fixed;
                    top: 120px;
                    left: 70%;
                    width: 30%;
                    z-index: 1;

                    a {
                        text-transform: none;
                        font-size: 1rem;
                    }
                    @media #{$mqTablet} {
                        display: none;
                    }
                }
            }
            .menu {
                padding-top: $mediumPadding;


                span {
                    color: white;
                    font-weight: bold;
                    padding: 0 10px;
                }
                
            }

        }

        .left-pannel {
            position: relative;
        }
        .section__actions {
            margin-top: $largePadding;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$mqMobile} {
                flex-direction: column;

                a:last-child {
                    margin-top: 30px;
                }
            }
        }

        [data-overlay-step] {
            // display: none;
            padding-top: 120px;
            position: absolute;
            top: -20px;
            left: 0;
            pointer-events: none;
            transform: translateY(-100%);
            opacity: 0;
            transition: .3s top ease-out, .3s opacity ease-out;

            @media #{$mqTablet} {
                padding-top: 60px;
                padding-bottom: 0;
            }
        }

        [data-overlay-step].active {
            position: relative;
            opacity: 1;
            top: 0;
            transform: translateY(0);
            transition: .3s top ease-out, .3s opacity ease-out;
            transition-delay: .3s;
            pointer-events: inherit;
        }

        
    }

    // JFL: This leaves some debt and is a fugly fix to
    // add a background image to the "sidebar", that is in fact
    // split in two with unrelated parents.
    .right-pannel-background {
        animation: rightPanelBgIn 1s $easeOutExpo;
        height: 100%;
        right: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: calc(100% - 1px);
        z-index: -1;

        img {
            height: 100vh;
            object-fit: cover;
            width: 100%;
        }
    }

    @keyframes rightPanelBgIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .right-pannel-content .right-pannel-background {
        height: 100vh;
        top: -$navHeight;
    }
}

.overlay-search {
    // height: auto;
    transition: transform .5s ease-out;
    display: none;
    // transform: translateY(-100%);
        
    .overlay-is-open & {
        // transform: translateY(0);
        &:before {
            opacity: 1;
        }
        .section {
            transform: translateY(0);
        }
    }

    .CoveoSearchInterface  {
        background: none;
        min-width: 0 !important; 
    }
    &:before {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 0;
        content:"";
        opacity: 0;
        transition: opacity .2s ease-out;
    }
    [data-js="button-close"] {
        position: absolute;
        top: -15px;
        right: 15px;
        height: auto;
        display: inline-block; 
        transition: transform .3s ease-out;
        backface-visibility: hidden;

        @include hover() {
            .icon_close {
                background: rgba(255,255,255,.6);
                color: $black;
                transform: none;
            }
        }
        @media #{$mqTablet} {
            right: 0;
            top: 20px;
        }
    }
    .icon_close {
        color: $black;
    }
    .section {
        padding: $largePadding 45px $largePadding;
        position: relative;
        z-index: 2;
        transform: translateY(-100%);
        transition: transform .2s linear;

        .wrap {
            padding: 0;
            // border-bottom: $navBorder;
            position: relative;
            z-index:2;
            width: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $gray;
            z-index:1;
        }
        @media #{$mqTablet} {
            padding: 0 $smallPadding $mediumPadding;
            .wrap {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    header {
        margin-bottom: $smallPadding;
        padding-left: 15px;

        h4 {
            font-weight: normal;
            color: $black !important;   //Fix for callout state
        }

        @media #{$mqTablet} {
            margin-top: $mediumPadding;
            margin-bottom: 10px;
            padding-left: 0;
        }

        @media #{$mqMobile} {
            margin-top: $smallPadding;
        }
    }
    form {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media #{$mqTablet} {
            flex-direction: column;
        }
    }
    .magic-box .magic-box-input > input {
        background: none!important;
        border:0!important;
        font-family: $fontBasis!important;
        // font-size: 6.5rem!important;
        @include large-title();



       
        // padding-right: $largePadding*2;
    }
    
    button {
        // flex: 0 1 30%;
        display: flex;
        align-items: center;
        
        @include icon--arrow-right('before');
        white-space: nowrap;

        &:before {
            padding-right: 10px;
            transition: padding .3s ease-out;
        }

        @include hover() {
            &:before {
                padding-right: 5px;
            }
        }
        @media #{$mqTablet} {
            align-self: flex-end;
        }
    }


    //overwrite coveo default CSS
    .CoveoSearchbox {
        position: relative;
        max-width: none !important;
        width: 100% !important;

        .CoveoForSitecoreExpressions, .CoveoForSitecoreConfigureSearchHub {
            display: none;
        }
        .magic-box {
            border: none;
            width: 100%;
            position: static;

            .magic-box-input {
                background: none;
                height: auto;  
                overflow: visible;
                padding: 0 15px; 
                @media #{$mqTablet} {
                    // padding: 0 15px 0 0;
                    padding: 0;
                }
                &:after {
                    content: '';
                    width: calc(100% + 60px);
                    display: block;
                    position: relative;
                    left: -30px;
                    // bottom: 0;
                    margin-top: 15px;
                    height: 1px;
                    background: rgba(0,0,0,0.1);

                    @media #{$mqTablet} {
                        width: 100%;
                        left: 0;
                        margin-top: 10px;
                    }
                }
                > input {
                    // margin-left: -5px;
                    // padding-right: 60px;
                    color: inherit;
                    padding: 0;
                    height: auto;
                    position: static;
                    text-indent: 0;
                    width: calc(100% - 40px);

                    @media #{$mqTablet} {
                        width: calc(100% - 30px);
                    }
                }
            }
            .magic-box-clear {
                display: none;
            }
            .magic-box-suggestions {
                // padding-top: $smallPadding;
                border: 0;
                // border-top: $navBorder;
                background: none;
                display: block;
                position: static;
                width: 100%;

                .CoveoExcerpt {
                    display: none;
                }
                .CoveoResult {
                    border: 0;
                    padding: 0 0;
                    .coveo-title {
                        font-family: $fontBasis;
                        font-size: 1.7em;
                        a {
                            color: $color;
                            padding: 25px;
                            display: block;
                            margin: 0;
                            .coveo-highlight {
                                font-weight: bold;
                            }
                            @include hover() {
                                text-decoration: none;
                                background: #bbbcbe;
                            }
                        }
                    }
                }
            }
        }
        .CoveoSearchButton {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 60px;
            border: 0;
            overflow: visible;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            float: none; // coveo override

            @media #{$mqTablet} {
                right: -15px;
            }

            .coveo-search-button {
                svg {
                    color: $black;

                }
            }

            &:hover {
                .coveo-search-button {
                    svg, path {
                        color: $white;
                        fill: $white;
                    }
                }
            }
            .coveo-search-button-loading {
                display: none;
            }
            @media #{$mqTablet} {

                // padding-top: 50px;
                .coveo-search-button {
                    button {
                        &:before {
                            font-size: 1.3rem;
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.coveo-search-component {
    .overlay-search {
        display: block;
    }
}

/*
   -- simple
   ========================================================================== */
.overlay--simple {
    background-color: rgba($black, .5);
    display: flex;
    align-items: center;

    .header {
        display: none;
    }

    .main {
        min-height: 0;
        height: calc(100vh - #{$largePadding * 2});
        transition: .6s $easeOutExpo;
        transition-property: height, width;
        width: 80%;

        @media #{$mqDesktop} {
            width: 90%;
        }

        @media #{$mqTabletP} {
            height: 100%;
            width: 100%;
        }   

        
        
        @media #{$mqLarge} {
            width: 70%;
        }
    }

    .icon_close {
        $size: 60px;

        // background-color: $white;
        color: $black;
        height: $size;
        position: absolute;
        right: $smallPadding;
        top: $smallPadding;
        width: $size;
    }

    iframe {
        background-color: $white;
        border: 0;
        height: 100%;
        width: 100%;
    }
}
