.nav--bar {
    transition: $gdprTransition;

        .gdpr-visible & {
            transform: translateY($gdprHeight);
        }
    @media #{$mqDesktop} {
        display: none;
    }
    @media #{$mqTablet} {
        
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: $backgroundColor;
        padding: 0 $mediumPadding;
        z-index: 202;


        &:after {
            content: '';
            background: #0058b8;
            position: absolute;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100%;
            z-index: -1;
            transition:  $calloutTransition;
            opacity: 0;
        }
        
        .nav__inner {
            display: flex;
            justify-content: flex-end;
            border-bottom: $navBorder;
        }

        
    }
    @media #{$mqMobile} {
        padding: 0 $smallPadding;
    }
}

.nav__bar-tools {
    @media #{$mqDesktop} {
        display: none;
    }
    @media #{$mqTablet} {
        display: flex;
        align-items: center;
        
    }

    .button {
        margin-left: 10px;
        @include hoverColor($white, $black); 
        &.icon_burger {
            @include hover() {
                &:after, &:before {
                    background: $white;
                }
            }
        }
        @if $theme == 'bcf-blue' {
            color: $white;
            @include hoverColor($blue, $white); 
            &.icon_burger {
                @include hover() {
                    &:after, &:before {
                        background: $blue;
                    }
                }
                &:after, &:before {
                    background: $white;
                }
            }
        }
        
    }
}