/*
 *
 *    Nav Contact
 *
 */

.nav--contact {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 30;
    width: 30%;
    pointer-events: none;
    

    .nav__ul {
        pointer-events: inherit; // prevents pointer-events: all from base .nav__ul
    }

    .nav__link-contact {
        pointer-events: auto; // allow this button to be clicked
    }

    .nav__inner {
        position: relative;

        &:before {
            width: 0;
            height: 1px;
            background: $grayLight;
            content: '';
            position: absolute;
            left: 0;
            top: -1px;
            transition: width 0.4s $easeInOutCubic;
        }
    }


    &.is-absolute, .has-tool-bar & {
        position: absolute;
        width: 100%;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -60px;    
        transform: translateY(-100%);
    }

    li {
        transition: $opacityTransition;
    }

    li:first-child {
        pointer-events: none;
        opacity: 0;
    }

    &.is-active {
        html.has-coveo-filters & {
            background: $gray;
            border-color: $black;

            .link--white {
                color: $black;
            }

            .nav__inner:before {
                background: rgba(0,0,0,0.1);
            }
        }
        .nav__inner {
            &:before {
                width: 100%;
            }
        }
        li:first-child {
            opacity: 1;
        }
    }
}
