button {
    background: transparent;
    border: none;
    font-size: inherit;
    cursor: pointer;
    outline: 0;
    padding: 0;
}

.button {
    &--light {
        color: $white;
        border-color: #1a1a1a;

        @include hoverColor($black, $white);
        
    }
}

.button--large {
    font-weight: bold;
    width: 100%;
    display: block;
    border-style: solid;
    border-width: 1px;
    border-radius: 60px;
    .button__inner {
        @include icon--arrow-right('after');
        padding: 20px 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
    }
}



.button--icon {
    @include flexCenter();
    border-radius: 100%;
    // padding: 10px;
    height: 34px;
    width: 34px;

    @media #{$mqTablet} {
        width: 37px;
        height: 37px;
    }

    font-size: 14px;
    background: $white;
    color: $black;
    transition: background 0.4s ease-out;
    .no-touchevents &:hover,
    .no-touchevents &:focus {
        background: $grayLight;
        color: $white;
    }
    &-gray-light {
        background: $secondaryColor;
        .no-touchevents &:hover,
        .no-touchevents &:focus {
            background: $white; 
        }
    }
    
}



@mixin icon--burger($pseudo: '') {
    position: relative;
    &:after, &:before {
        content: '';    
        background: $black;
        height: 2px;
        left: 50%;
        width: 10px;
        position: absolute;
        transition: transform 0.4s ease-out, width 0.4s ease-out;
        transform: translateX(-50%);
        transform-origin: 3px;
    }

    &:before {
        top: 14px;
        @media #{$mqTablet} {
            top: 15px;
        }
        .is-opened & {
            transform: translateX(-52%) rotate(45deg) scaleX(1.8);
        }
    }

    &:after {
        top: 19px;
        @media #{$mqTablet} {
            top: 20px;
        }
        .is-opened & {
            transform: translateX(-52%) rotate(-45deg) scaleX(1.8);
        }
    }

}
 
 .icon {
     &_burger {
         @include icon--burger('');
         
         &.icon_before {
             @include icon--burger('before')
         }
     }
 }