form {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    h1 {
        margin-bottom: $largePadding*2; 
        @media #{$mqTablet} {
            margin-bottom: 80px; 
        }
    }
    button {
        text-align: left;
    }
    .form_step {
        &:not(:first-child) {
            margin-top: 30px;
        }
        .section__heading {
            &:before, &:after {
                background: rgba($borderInvert,0.15);
            }
            &:after {
                width: calc(100% + 40px);
            }
            display: block; 
            margin-bottom: $largePadding * 1.5;
            .heading {
                background: $blue;
                color: $white;
            }
        }
    }
    .error {
        border-color: red!important;
        color: red!important;
        > label {
            color: red;
            opacity:1;
            font-weight: bold;
        }
        > .file_upload_wrapper:before {
            border-color: red!important;
        }
    }
    .multiple_field {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        .field {
            flex-basis: 50%;
            width: 50%;
            // padding-left: 20px;
            // padding-bottom: 0;
            @media #{$mqMobile} {
                flex-basis: 100%;
                width: 100%;
            }
            input,label {
                margin-left: $largePadding/2;
                @media #{$mqTablet} {
                    margin-left: $mediumPadding/2;
                }
                @media #{$mqMobile} {
                    margin-left: 0;
                }
            }
            label {
                padding-right: 40px;
            }
            &:first-of-type {
                input, label {    
                    margin-right: $largePadding/2;
                    margin-left: 0;

                    @media #{$mqTablet} {
                        margin-right: $mediumPadding/2;
                    }

                    @media #{$mqMobile} {
                        margin-right: 0;
                    }
                }
                .file_upload_wrapper:after {
                    right: $largePadding/2;
                    @media #{$mqTablet} {
                        right: $mediumPadding/2;
                    }
                    @media #{$mqMobile} {
                        right: 0;
                    }
                }
            }
        }

        @media #{$mqTablet} { 
            > div {
                flex-basis: 100%;
                width: 100%;

                padding: 0;
                &:first-of-type {
                    padding: 0;
                    padding-bottom: 30px;
                    @media #{$mqTablet} {
                        padding: 0;
                    }
                }
            }
        }
    }
    div {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .field {
        margin-bottom: $largePadding*1.5;
        // @media #{$mqTablet} {
        //     margin-bottom: 40px;
        // }
    }
    label {
        // font-size: 1rem;
        @include small-title();

        cursor: text;
        color: $white;
        text-transform: uppercase;
        opacity: .6;
        position: absolute;
        top: 0;
        transform: translateY(6px);
        transition: transform .3s ease-out;
        &.focused {
            transform: translateY(-36px);
        }
    }
    input:not([type='checkbox']), textarea {
        background: none;
        border:0;
        outline: 0;
        color: $white;
        font-family: $fontBasis;
        font-size: 1.25rem;
        padding-bottom: $smallPadding;
        border-bottom: 1px $white solid;
        // margin-bottom: $largePadding;
    }
    textarea {
        vertical-align: bottom;
        resize: none;
    }
    button.cta[data-cta-type="dots"] {
        margin: 20px auto 0;
        svg circle {
            fill: rgba($white, 0.3);
        }
    }
}

input {
    outline: none;
    // pointer-events: none;
}

.file_upload_wrapper {
    position: relative;
    // margin-right: 20px;

    &:before {
        width: calc(100% - #{$largePadding/2});
        content: '';
        border-bottom: 1px solid $white;
        // height: 2px;
        position: absolute;
        left: $largePadding/2;
        bottom: 0;

        @media #{$mqTablet} {
            width: calc(100% - #{$mediumPadding/2});
            left: $mediumPadding/2;
        }

        @media #{$mqMobile} {
            width: 100%;
            left: 0;
        }
    }

    .field:nth-child(odd) & {
        &:before {
            left: 0;
        }
    }
    .field:nth-child(even) & {
        .input_value {
            margin-left: 30px;
            @media #{$mqTablet} {
                margin-left: 20px;
            }
            @media #{$mqMobile} {
                margin-left: 0;
            }
        }
    }

   
   .file_upload {
        opacity: 0;
        cursor: pointer;
        
   }
   .input_value {
        color: $white;
        font-size: 1.25rem;
        position: absolute;
        top: 5px;
        left: 0;
        padding-right: 40px;
    }
    @include icon--upload('');

    &:after {
        display: block; 
        position: absolute;
        top: 5px;
        right: 0;
        color: $white;
        pointer-events: none;
    }
}
.multiple_field.check {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // flex-wrap: nowrap;
    margin-bottom: $largePadding;

    span {
        color: $white;
        margin-right: 20px;
        @media #{$mqMobile} {
            flex-basis: 100%;
            width: 100%;
            margin-bottom: 20px;
        }
        // font-size: 1.25rem;
    }
    label {
        position: static;
        transform: none;
        margin-left: 10px;
        display: flex;
        align-items: center;
        opacity: 1;
        line-height: 12px;
        text-transform: none;
        font-size: 1rem;
        cursor: pointer;
        @media #{$mqMobile} {
            margin-left: 0;
        }
    }
    input {
        background: rgba($white, .3);
        // opacity: .6;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        margin-right: 10px;
        margin-top:0;
        margin-bottom: 0;
    }
    input:checked {
        background: $white;
    }
}
.form-steps-list {
    display: flex;
    flex-direction: column;
    .link {
        margin-bottom: 20px;
        position: relative;
        opacity: .6;
        transition: opacity .3s ease-out;

        &:after {
            @include bullet();
            background: $white;
            position: absolute;
            opacity: .8;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }

        &.is-active, &:hover {
            opacity: 1;
            // color: white!important;
        }

        &:focus, &:hover {
            // opacity: .6;
            color: $white !important;
        }

        // &:hover {
        //     opacity: 1;
        // }

        &.finished {
            opacity: 1;
            @include icon--check('after');

            &:after {
                font-size: 12px;
                background: none;
                width: auto;
                height: auto;
            }
        }
    }
}
.alert {
    margin-top: $mediumPadding;
    color: $white;
    font-style: italic;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

textarea { overflow: auto; }