/*
 ***************************************************************
 * Colors
 ***************************************************************
 */
 
$white: #fff;
$black: #000;
$gray: #a5a6a7;
$grayLight: rgba($gray, 0.6);
$grayMedium: rgba(0,0,0,0.2);
$grayDark: #454647;
$blue: #0058b8;
$blendModeFilter: #003e80;
$borderInvert: #c5c6c7;

$backgroundColor: $white;
$color: $black;
$secondaryColor: $grayMedium;
// $imageSlideGradient: linear-gradient(to bottom, #24476e 0%,#2a4c73 10%,#39597d 24%,#446284 39%,#446284 61%,#39597d 76%,#2a4c73 90%,#24476e 100%);
$imageSlideGradient: linear-gradient(to bottom, rgba(#24476e,0.6) 0%, rgba(#446284,0.4) 40%, rgba(#446284,0.4) 60%, rgba(#24476e,0.6) 100%);
$imageSlideGradientBlue: linear-gradient(to bottom, rgba(#0058b8,0.9) 0%, rgba(#0058b8,0.8) 40%, rgba(#0058b8,0.8) 60%, rgba(#0058b8,0.9) 100%);
